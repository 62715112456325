import { useQuery, useMutation, QueryClient } from "react-query";
import {
  loginWithEmail,
  signupWithEmail,
  getUser,
  getLogOut,
  forgetPassword,
  verifyOtp,
  updatePassword
} from "../store/services/service";

export const useSignup = () => {
  return useMutation(signupWithEmail);
};

export const useLogin = () => {
  return useMutation(loginWithEmail);
};

export const useVerifyAccessToken = () => {
  return useQuery("verifyAccessToken", getUser);
};
export const useLogOut = () => {
  return useMutation(getLogOut);
};

export const useForgetPassword = () =>{
  return useMutation('forgetpassword', forgetPassword)
}
export const useVerifyOtp = () =>{
  return useMutation('verifyotp', verifyOtp)
}
export const useUpdatePassword = () =>{
  return useMutation('updatepassword', updatePassword)
}

