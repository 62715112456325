import React, { useEffect, useState, useRef, useContext } from "react";
import AppleIcon from "../../../assests/appleIcon.png";
import { AutoComplete, Input, Spin } from "antd";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useGetCompaniesOnSearch,
  useGetCompanyDataOnSelect,
} from "../../../Hooks/charts.hook";
import { Modal } from "antd";
import Tabs from "./Tabs";
import "../style/style.css";
import CountryFlag from "react-country-flag";
import { ThemeContext } from "../../../Context/ThemeContext";
import TabSearch from "./TabSearch";
import { CloseOutlined } from "@ant-design/icons";

function SearchBarComponent({ selectOnClick, setCompanyInfo }) {
  const { darkMode } = useContext(ThemeContext);
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [placeholder, setPlaceholder] = useState("Search");
  const [filters, setFilters] = useState([
    "ALL",
    "AMEX",
    "NASDAQ",
    "NYSE",
    "EURONEXT",
    "XETRA",
    "NSE",
    "LSE",
  ]);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const {
    data: searchData,
    remove: removeSearchReq,
    status: searchDataStatus,
    isLoading: searchDataLoading,
  } = useGetCompaniesOnSearch(
    searchTerm == "" ? "AA" : searchTerm,
    selectedFilter
  );

  const {
    data: companyData,
    remove: removeCompanyDataReq,
    status: companyDataStatus,
  } = useGetCompanyDataOnSelect(selectedSymbol);

  useEffect(() => {
    if (searchDataStatus === "success" && searchData.status === 200) {
      // console.log(searchData.data);
      setOptions(searchData.data);
    }
  }, [searchDataStatus, searchData]);

  useEffect(() => {
    if (companyDataStatus === "success" && companyData.status === 200) {
      setCompanyInfo(companyData.data[0]);
      if (companyData.data.length > 0) {
        setPlaceholder(companyData.data[0].symbol);
      }
    }
  }, [companyDataStatus, companyData]);

  const handleSearch = (value) => {
    removeSearchReq();
    setSearchTerm(value);
  };

  const onSelect = (value) => {
    console.log("onSelect", value);
    setOpen(false);
    removeCompanyDataReq();
    setSelectedSymbol(value);

    //clear modal search bar
    // removeSearchReq();
    // setSearchTerm("");

    selectOnClick(value, selectedFilter);
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      inputRef.current.focus({
        cursor: "end",
      });
      inputRef.current.select();
    }, 100);
  };

  const handleCancel = () => {
    setOpen(false);
    // removeSearchReq();
    // setSearchTerm("");
  };
  return (
    <div className="w-full h-24">
      <div className="flex flex-col ml-5">
        <div className="flex h-[50px] w-80">
          {/* //small search bar */}

          <div className="flex flex-row" onClick={handleClick}>
            <div
              className={
                darkMode
                  ? "flex h-9 w-[8rem] bg-white rounded-md"
                  : "flex h-9 w-[8rem] bg-gray-300 rounded-md"
              }
            >
              <button className="h-8 w-10 rounded-tr-lg rounded-br-lg ">
                <FontAwesomeIcon icon={faMagnifyingGlass} color={"#000"} />
              </button>
              <p
                className={
                  placeholder == "Search"
                    ? "flex pt-1 text-gray-800 "
                    : "flex pt-1 text-black font-bold"
                }
              >
                {placeholder}
              </p>
            </div>
          </div>

          {/* //modal search bar */}
          <Modal
            title={
              <div
                style={{
                  backgroundColor: darkMode ? "#282c34" : "#fff",
                  padding: "20px",
                  paddingLeft: "24px",
                  color: darkMode ? "#fff" : "#000",
                }}
              >
                Symbol Search
              </div>
            }
            centered
            open={open}
            width={800}
            onCancel={handleCancel}
            footer={null}
            closeIcon={
              <CloseOutlined style={{ color: darkMode ? "white" : "" }} />
            }
            bodyStyle={{
              height: 430,
              paddingRight: "20px",
              paddingLeft: "20px",
              backgroundColor: darkMode ? "#282c34" : "#fff",
            }}
          >
            <AutoComplete
              // dropdownMatchSelectWidth={252}
              // options={options}
              onSearch={handleSearch}
              style={{ width: "100%" }}
            >
              <Input.Search
                size="large"
                placeholder="Search a company"
                className="rounded-xl"
                ref={inputRef}
                enterButton={
                  <button className=" h-10 bg-gray-500 p-[12px] rounded-tr-lg rounded-br-lg hover:bg-gray-950">
                    <FontAwesomeIcon icon={faMagnifyingGlass} color={"#fff"} />
                  </button>
                }
                onPressEnter={() => {
                  // console.log(options[0].symbol);
                  onSelect(options[0].symbol);
                }}
              />
            </AutoComplete>

            <div className="flex pt-2 space-x-1" id="tagsList">
              {filters.map((item, index) => {
                return (
                  <TabSearch
                    key={index}
                    text={item}
                    tabOnclick={() => {
                      setSelectedFilter(item);
                      setOptions([]);
                      handleSearch(searchTerm);
                    }}
                    myactiveTab={item}
                    activeTab={selectedFilter}
                    period={""}
                  />
                );
              })}
            </div>

            {searchDataLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "8rem",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <div className="flex flex-col h-[20rem] overflow-auto mt-2">
                {options.map((item) => {
                  return (
                    <div className={darkMode ? "text-white" : "text-black"}>
                      <div
                        className="flex justify-between p-1 ml-1 hover:bg-gray-300 cursor-pointer rounded-md"
                        onClick={() => {
                          onSelect(item.symbol);
                        }}
                      >
                        <div className="flex w-56">
                          {/* <span class="w-7 h-7 mt-1 mr-4 bg-gray-400 rounded-full">
                          <p className="flex justify-center p-1 text-sm font-bold">
                            {item.symbol[0]}
                          </p>
                        </span> */}
                          <img
                            src={item.img}
                            className="h-7 w-7 ml-1 mr-6 bg-[#000] rounded-full mb-2"
                          />
                          <h4 className="text-base font-semibold mt-1">
                            {item.symbol}
                          </h4>
                        </div>
                        <div className="w-[35rem]">
                          <h5 className="mt-1">{item.name}</h5>
                        </div>
                        <div className="flex justify-end w-48 mr-3">
                          <span className="px-2 mt-1">
                            {item.currency == "GBp" ? "GBP" : item.currency}
                          </span>
                          <p className="mt-1">{item.exchangeShortName}</p>
                        </div>

                        <CountryFlag
                          countryCode={item.country}
                          style={{
                            width: "24px",
                            height: "24px",
                            paddingTop: 6,
                            // borderRadius: "100%",
                            // backgroundColor: "#000",
                          }}
                          svg
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="w-full">
  //     <div className="flex flex-col sm:flex-row justify-center items-center">
  //       {companyInfo && (
  //         <>
  //           <img
  //             src={companyInfo.image}
  //             alt="companyIcon"
  //             className="h-[120px] w-[120px] text-white ml-5 bg-gray-950 p-5 rounded-full"
  //           />

  //           <div className="w-1/4 flex-row pl-1">
  //             <div className="pl-5 ">
  //               <h1 className="text-[1rem] md:text-xl font-semibold">
  //                 {companyInfo.companyName} ({companyInfo.symbol})
  //               </h1>
  //               <h1 className="text-[1rem] md:text-xl ">
  //                 ${companyInfo.price}
  //               </h1>
  //             </div>
  //           </div>
  //         </>
  //       )}
  //       <div className="w-3/4 flex justify-center items-center ">
  //         <AutoComplete
  //           dropdownMatchSelectWidth={252}
  //           options={options}
  //           onSelect={onSelect}
  //           onSearch={handleSearch}
  //         >
  //           <Input.Search
  //             size="large"
  //             placeholder="Search a company"
  //             className="rounded-xl"
  //             enterButton={
  //               <button className="bg-gray-500 p-[13px] rounded-tr-lg rounded-br-lg hover:bg-gray-950 ">
  //                 <FontAwesomeIcon icon={faMagnifyingGlass} color={"#fff"} />
  //               </button>
  //             }
  //           />
  //         </AutoComplete>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default SearchBarComponent;
