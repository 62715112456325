import Header from "./common/components/Header";
import MainPage from "./pages/GraphPage";
import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Protected from "./routes/Protected";
import OtpPage from "./common/components/Otp/OtpPage";
import ResetPassword from "./common/components/ResetPassword/ResetPassword";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/dashboard" element={<Protected Component={MainPage} />} />
        {/* <Route path="/dashboard" element={<MainPage />} /> */}
        <Route path="/otp" element={<OtpPage />} />
        <Route path="/resetpassword" element={<ResetPassword />} />


      </Routes>
    </>
  );
}

export default App;
