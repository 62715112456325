import React from "react";
import { useState, useEffect } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import loginLottie from "../../assests/login.json";
import AnimationLottie from "../../common/lottieHelper/AnimationLottie";
import { useVerifyAccessToken } from "../../Hooks/auth.hook";
import { useLogin, useForgetPassword } from "../../Hooks/auth.hook";
import { ToastContainer, toast } from "react-toastify";

const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password must be at least 4 characters"),
});

const ForgetPassEmailPage = () => {
  const [showLoader, setShowLoading] = useState(false);
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const { mutate, status, isError, data } = useForgetPassword();
  // const formik = useFormik()

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is Required"),
  });
  useEffect(() => {
    if (status == "success") {
      console.log(data.status);
      if (data.data.status == 200) {
        toast.success(data?.data?.message, {
          autoClose: 1000,
          onClose: () => navigate("/otp", { state: email }),
        });

        console.log("sucessfull login data: ", data);
      } else if (data.data.status == 403) {
        toast.error(data.data.message, {
          autoClose: 1500,
        });
        setShowLoading(false);
      } else if (data.status == 403) {
        toast.error("something went wrong", {
          autoClose: 1500,
        });
        setShowLoading(false);
      }
    }
  }, [data, status]);
  return (
    <div className="h-screen flex flex-row  ">
      <ToastContainer />
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setEmail(values.email);
          console.log(values);
          // navigate('/resetpassword')
          // setShowLoading(true);
          mutate(values);
        }}
      >
        {({ errors, touched, values }) => (
          <>
            <Form className="bg-white py-9 px-10  w-full md:w-[50%] flex flex-col justify-center items-center">
              <div>
                <p className="text-center mb-4">Enter Your Email</p>

                <div className="relative text-gray-800 mt-2 ">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className=" ml-2 mt-2 w-4 h-4 "
                    />
                  </span>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    className={`w-full py-2 pl-10 mt-2  border ${
                      errors.email && touched.email ? "border-red-500" : ""
                    }`}
                  />
                </div>
                {errors.email && touched.email ? (
                  <div className="text-red-500">{errors.email}</div>
                ) : null}

                <div className="">
                  <button
                    type="submit"
                    className={` text-white py-2 mt-8 px-12 sm:px-28 rounded-lg  ${
                      showLoader
                        ? "bg-transparent border border-blue"
                        : "bg-blue-500 hover:bg-blue-600"
                    }`}
                  >
                    <div>
                      {showLoader ? (
                        <div className="d-flex justify-center">
                          <Spin />
                        </div>
                      ) : (
                        "Send OTP"
                      )}
                    </div>
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
      <div className="w-[50%] hidden  md:flex justify-center items-center bg-gradient-to-r from-[#C0DBEA] via-30% to-[#FCC8D1] to-90% ">
        <AnimationLottie animationPath={loginLottie} />
      </div>
    </div>
  );
};

const SignIn = () => {
  const [showLoader, setShowLoading] = useState(false);
  const [verified, setVerified] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const {
    data: verifyData,
    status: verificationStatus,
    remove,
  } = useVerifyAccessToken();
  const { mutate, status, isError, data, error } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (verificationStatus == "success" && verifyData.status == 200) {
      navigate("/dashboard");
      // console.log("data: ", data);
    } else {
      setVerified(false);
    }
  }, [verifyData, verificationStatus]);

  useEffect(() => {
    if (status == "success") {
      if (data.status == 200) {
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
        console.log("sucessfull login data: ", data);
      } else if (data.status == 403) {
        toast.error("invalid credentials", {
          autoClose: 1500,
        });
        setShowLoading(false);
      } else if (data.status == 500) {
        toast.error("Account not verified", {
          autoClose: 1500,
        });
        setShowLoading(false);
      }
    }
  }, [data, status]);

  const handleClick = () => {
    window.location.assign(
      `${process.env.REACT_APP_BASE_URL}/auth/google/login`
    );
  };

  return forgotPassword ? (
    <ForgetPassEmailPage />
  ) : (
    <div className="h-screen flex flex-row  ">
      <ToastContainer />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={SignInSchema}
        onSubmit={(values) => {
          // console.log(values);
          setShowLoading(true);
          mutate(values);
        }}
      >
        {({ errors, touched }) => (
          <>
            <Form className="bg-white py-9 px-10  w-full md:w-[50%] flex flex-col justify-center items-center">
              <div>
                <p className="text-center text-blue-700 my-3 text-[1.3rem] md:text-[1.6rem] font-bold">
                  {/* Logo */}
                </p>

                <p className="text-center text-blue-700 my-3 text-[1.3rem] md:text-[1.6rem] font-medium ">
                  Sign in to your Account
                </p>
                <p className="text-center mb-4">Welcome back!</p>

                <div className="relative text-gray-800 mt-2 ">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className=" ml-2 mt-2 w-4 h-4 "
                    />
                  </span>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    className={`w-full py-2 pl-10 mt-2  border ${
                      errors.email && touched.email ? "border-red-500" : ""
                    }`}
                  />
                </div>
                {errors.email && touched.email ? (
                  <div className="text-red-500">{errors.email}</div>
                ) : null}

                <div className="relative text-gray-800 mt-4">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FontAwesomeIcon
                      icon={faLock}
                      className="ml-2 mt-2 w-4 h-4 "
                    />
                  </span>
                  <Field
                    id="password"
                    name="password"
                    placeholder="Password"
                    type="password"
                    className={`w-full py-2 pl-10  mt-2 border ${
                      errors.password && touched.password
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                </div>

                {errors.password && touched.password ? (
                  <div className="text-red-500">{errors.password}</div>
                ) : null}
                <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
                  <label
                    htmlFor="rememberMe"
                    className="inline-flex items-center"
                  >
                    <Field
                      id="rememberMe"
                      name="rememberMe"
                      type="checkbox"
                      className="form-checkbox h-3 w-3 text-blue-600 bg-gray-200"
                    />
                    <span className="ml-1 text-gray-700">Remember me</span>
                  </label>
                  <span
                    onClick={() => setForgotPassword(true)}
                    className="text-sm text-blue-500 hover:text-blue-700 cursor-pointer"
                  >
                    Forgot Password?
                  </span>
                </div>

                <div className="">
                  <button
                    type="submit"
                    className={` text-white py-2 mt-8 px-12 sm:px-28 rounded-lg  ${
                      showLoader
                        ? "bg-transparent border border-blue"
                        : "bg-blue-500 hover:bg-blue-600"
                    }`}
                  >
                    <div>
                      {showLoader ? (
                        <div className="d-flex justify-center">
                          <Spin />
                        </div>
                      ) : (
                        "Signin"
                      )}
                    </div>
                  </button>
                </div>
                <div className="flex items-center my-6">
                  <hr className="flex-grow border-gray-950 mr-3" />
                  <span className="text-gray-500 font-medium">
                    Or continue with
                  </span>
                  <hr className="flex-grow border-gray-400 ml-3" />
                </div>
                <div className="mb-2 flex justify-center items-center">
                  <button
                    type="button"
                    className="flex items-center justify-center px-4 py-2 mx-2 text-red-700 bg-white rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 hover:bg-slate-100"
                    onClick={handleClick}
                  >
                    <FontAwesomeIcon icon={faGoogle} className="mr-2 text-lg" />
                    Google
                  </button>
                </div>
                <p className="text-center mt-6">
                  Don't have an account?
                  <span
                    className="text-semibold text-blue-700 cursor-pointer"
                    onClick={() => navigate("/signup")}
                  >
                    signup
                  </span>
                </p>
              </div>
            </Form>
            <div className="w-[50%] hidden  md:flex justify-center items-center bg-gradient-to-r from-[#C0DBEA] via-30% to-[#FCC8D1] to-90% ">
              <AnimationLottie animationPath={loginLottie} />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default SignIn;
