import React, { useState, useContext } from "react";
import { ThemeContext } from "../../../Context/ThemeContext";

function TabSearch({ text, tabOnclick, myactiveTab, activeTab, period }) {
  const { darkMode } = useContext(ThemeContext);

  return (
    <div>
      <p
        className={
          activeTab === myactiveTab
            ? " font-roboto cursor-pointer pb-2 bg-gray-950 rounded-full text-white p-1 px-5 font-bold"
            : " font-roboto  hover:bg-gray-300 cursor-pointer bg-gray-300 rounded-full p-1 px-5"
        }
        onClick={() => {
          tabOnclick(myactiveTab, period);
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default TabSearch;
