import React, { useEffect, useState, useContext } from "react";
import { Modal, Spin } from "antd";
import { ThemeContext } from "../../../Context/ThemeContext";
import { CloseOutlined } from "@ant-design/icons";
const CustomModal = ({
  visible,
  onCancel,
  display,
  setDisplay,
  children,
  isModalOpen,
}) => {
  const { darkMode } = useContext(ThemeContext);
  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => setDisplay(true), 1000);
    }
  }, [isModalOpen]);
  return (
    <>
      <Modal
        // title={title}
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={1350}
        height={700}
        closeIcon={<CloseOutlined style={{ color: darkMode ? "white" : "" }} />}
        bodyStyle={{
          overflowY: "auto",
          maxHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: darkMode ? "#282c34" : "#fff",
          padding: "10px",
          paddingTop: "45px",
          paddingBottom: "45px",
        }}
      >
        {display ? (
          children
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "21.4rem",
              marginTop: "16rem",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </Modal>
    </>
  );
};

export default CustomModal;
