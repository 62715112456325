import { postRequest, getRequest } from "./api";
import {
  // FORGET_PASSWORD,
  USER_LOGIN,
  USER_REGISTER,
  USER_LOGOUT,
  GET_USER,
  GET_API_ONE,
  GET_API_TWO,
  GET_API_THREE,
  GET_API_FOUR,
  GET_API_FIVE,
  GET_API_SIX,
  GET_API_TWO_AND_FOUR,
  GET_API_TWO_AND_SEVEN,
  GET_API_TWO_AND_SIX,
  GET_API_EPS_DILUTED,
  GET_API_ONE_FOUR_AND_SIX,
  GET_API_TWO_THREE_AND_SIX,
  GET_COMPANIES_ON_SEARCH,
  GET_COMPANY_DATA_ON_SELECT,
  FORGET_PASSWORD,
  VERIFY_OTP,
  UPDATE_PASSWORD
} from "./endpoints";

export const signupWithEmail = (data) => postRequest(USER_REGISTER, data);

// export const loginWithEmail = (data) => postRequest(USER_LOGIN, data);

export const forgetPassword = (data) => postRequest(FORGET_PASSWORD, data);
export const verifyOtp = (data) => postRequest(VERIFY_OTP, data);
export const updatePassword = (data) => postRequest(UPDATE_PASSWORD, data);


// export const getUserLogout = () => getRequest(USER_LOGOUT);

export const loginWithEmail = (data) => postRequest(USER_LOGIN, data);

export const getUser = () => getRequest(GET_USER);

export const getApiOne = async (period, symbol, dailyData) =>
  getRequest(GET_API_ONE(period, symbol, dailyData));

export const getApiTwo = async (period, symbol) =>
  getRequest(GET_API_TWO(period, symbol));

export const getApiThree = async (period, symbol) =>
  getRequest(GET_API_THREE(period, symbol));

export const getApiFour = async (period, symbol) =>
  getRequest(GET_API_FOUR(period, symbol));

export const getApiFive = async (period, symbol) =>
  getRequest(GET_API_FIVE(period, symbol));

export const getApiSix = async (period, symbol) =>
  getRequest(GET_API_SIX(period, symbol));

export const getApiTwoAndFour = async (period, symbol) =>
  getRequest(GET_API_TWO_AND_FOUR(period, symbol));

export const getApiTwoAndSeven = async (period, symbol) =>
  getRequest(GET_API_TWO_AND_SEVEN(period, symbol));

export const getApiTwoAndSix = async (period, symbol) =>
  getRequest(GET_API_TWO_AND_SIX(period, symbol));

export const getApiEpsDiluted = async (period, symbol) =>
  getRequest(GET_API_EPS_DILUTED(period, symbol));

export const getApiOneFourAndSix = async (period, symbol) =>
  getRequest(GET_API_ONE_FOUR_AND_SIX(period, symbol));

export const getApiTwoThreeAndSix = async (period, symbol) =>
  getRequest(GET_API_TWO_THREE_AND_SIX(period, symbol));

export const getCompaniesOnSearch = async (searchTerm, exchange) =>
  getRequest(GET_COMPANIES_ON_SEARCH(searchTerm, exchange));

export const getCompanyDataOnSelect = async (selectedSymbol) =>
  getRequest(GET_COMPANY_DATA_ON_SELECT(selectedSymbol));

export const getLogOut = () => postRequest(USER_LOGOUT);

// export const addCourse = (data) => postRequest(ADD_COURSE, data);

// export const getQuestions = async (id) => await getRequest(GET_QUESTIONS(id));
