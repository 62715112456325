import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { useMediaQuery } from "react-responsive";
import Chart from "react-apexcharts";
import "../style/style.css";
import { ExpandAltOutlined } from "@ant-design/icons";
import "../style/style.css";
import CustomModal from "../components/CustomModal";
import Tabs from "../components/Tabs";
import moment from "moment/moment";
import getSymbolFromCurrency from "currency-symbol-map";
import { ThemeContext } from "../../../Context/ThemeContext";
import Cagr from "../components/Cagr";

dayjs.extend(quarterOfYear);

function AreaChart({
  allDataP,
  allDataMC,
  years,
  dailyRequest,
  p,
  mc,
  currencyText,
  cagrPrice,
  cagrMC,
}) {
  // console.log("allDataP", allDataP, "allDataMC", allDataMC);
  const { darkMode } = useContext(ThemeContext);
  // console.log(cagrPrice, cagrMC);
  const [price, setPrice] = useState("");
  const [marketCap, setMarketCap] = useState("");
  const [chartValue, setChartValue] = useState("close");
  const [filterValue, setFilterValue] = useState("max");
  const [currency, setCurrency] = useState(getSymbolFromCurrency(currencyText));

  const [smallChartData, setSmallChartData] = useState(null);
  const [allData, setAllData] = useState(null);

  const [options, setOptions] = useState({});
  const [modalOptions, setModalOptions] = useState({});

  const [smallChartSeries, setSmallChartSeries] = useState([]);
  const [modalSeries, setModalSeries] = useState([]);

  const [color, setColor] = useState(
    chartValue == "close" ? ["#43be29"] : ["#ff6962"]
  );

  const [visible, setVisible] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dispaly, setDisplay] = useState(false);
  const [smallDisplay, setSmallDisplay] = useState(false);

  const [smallFlag, setSmallFlag] = useState();
  const [modalFlag, setModalFlag] = useState();
  const [priceNull, setPriceNull] = useState(false);
  const [mcNull, setMcNull] = useState(false);

  const [filterYearSmall, setFilterYearSmall] = useState("2019");
  const [filterYearModal, setFilterYearModal] = useState("2022"); //initialize on 1 year

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDisplay(false);
  };

  useEffect(() => {
    // console.log(mc);
    let pUnit = "";
    if (p >= 1000000000) {
      p = p / 1000000000;
      pUnit = "B";
    } else if (p >= 1000000) {
      p = p / 1000000;
      pUnit = "M";
    } else if (p >= 1000) {
      p = p / 1000;
      pUnit = "K";
    } else if (p < 0) {
      if (p <= -1000000000) {
        p = p / 1000000000;
        pUnit = "B";
      } else if (p <= -1000000) {
        p = p / 1000000;
        pUnit = "M";
      } else if (p <= -1000) {
        p = p / 1000;
        pUnit = "K";
      }
    }
    setPrice(`${p.toFixed(1)} ${pUnit}`);

    let mcUnit = "";
    if (mc >= 1000000000) {
      mc = mc / 1000000000;
      mcUnit = "B";
    } else if (mc >= 1000000) {
      mc = mc / 1000000;
      mcUnit = "M";
    } else if (mc >= 1000) {
      mc = mc / 1000;
      mcUnit = "K";
    } else if (mc < 0) {
      if (mc <= -1000000000) {
        mc = mc / 1000000000;
        mcUnit = "B";
      } else if (mc <= -1000000) {
        mc = mc / 1000000;
        mcUnit = "M";
      } else if (mc <= -1000) {
        mc = mc / 1000;
        mcUnit = "K";
      }
    }
    setMarketCap(`${mc.toFixed(1)} ${mcUnit}`);
  }, []);

  useEffect(() => {
    if (filterValue == "7d" || filterValue == "1m") {
      setAllData(chartValue == "close" ? allDataP : allDataMC);
      setTimeout(() => {
        // console.log("1");
        setDisplay(true);
      }, 800);
    } else {
      setModalFlag(!modalFlag);
    }
  }, [allDataP, allDataMC]);

  useEffect(() => {
    setDisplay(false);
  }, [filterValue]);

  // console.log("filterValue", filterValue);

  useEffect(() => {
    if (allDataP.length == 0) {
      setPriceNull(true);
    }

    if (allDataMC.length == 0) {
      setMcNull(true);
    }
  }, []);

  const filter = (val, filterVal, period) => {
    let date;
    if (filterVal == "ytd") {
      const a = moment().format("YYYY");
      date = a + "-01-01";
      setFilterYearModal(date);
    } else {
      //update filter year
      const a = moment().subtract(val, period).calendar();
      date =
        a.substring(6, 10) + "-" + a.substring(0, 2) + "-" + a.substring(3, 5);
      setFilterYearModal(date);
      //update filter value
    }
    setFilterValue(filterVal);
    return date;
  };

  useEffect(() => {
    const smallChartP = allDataP.filter((item) => {
      const date = item.date;
      return date >= filterYearSmall;
    });
    const smallChartMC = allDataMC.filter((item) => {
      const date = item.date;
      return date >= filterYearSmall;
    });

    setSmallChartData(chartValue == "close" ? smallChartP : smallChartMC);
  }, [smallFlag]);

  useEffect(() => {
    if (filterValue == "max") {
      setAllData(chartValue == "close" ? allDataP : allDataMC);

      if (isModalOpen == true) {
        setTimeout(() => {
          // console.log("2");
          setDisplay(true);
        }, 4000);
      }
    } else if (filterValue !== "7d" && filterValue !== "1m") {
      const modalChartP = allDataP.filter((item) => {
        const date = item.date;
        return date >= filterYearModal;
      });
      const modalChartMC = allDataMC.filter((item) => {
        const date = item.date;
        return date >= filterYearModal;
      });
      setAllData(chartValue == "close" ? modalChartP : modalChartMC);
      setTimeout(() => {
        // console.log("3");
        setDisplay(true);
      }, 1200);
    }
  }, [modalFlag, filterValue, chartValue]);
  useEffect(() => {
    setSmallDisplay(false);
    if (smallChartData !== null && allData !== null) {
      smallChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
      allData.sort((a, b) => new Date(a.date) - new Date(b.date));

      // let denominator;
      // if (unit == " th") {
      //   denominator = 1000;
      // } else if (unit == " m") {
      //   denominator = 1000000;
      // } else if (unit == " B") {
      //   denominator = 1000000000;
      // }

      setTimeout(() => setSmallDisplay(true), 3);

      //for small series
      let smallChartSeriesObjects = [];

      const array = [chartValue];
      smallChartSeriesObjects = array.map((option) => {
        const a = smallChartData.map((item) => {
          return item[option] ? item[option] : 0;
        });
        return {
          // name: newOptionsObject[option],
          name: chartValue,
          data: a,
        };
      });

      const smallChartSeriesObjectArray = smallChartSeriesObjects.filter(
        (item) => {
          return item !== undefined;
        }
      );

      // console.log("smallChartSeriesObjectArray", smallChartSeriesObjectArray);

      // for modal series

      const modalSeriesObjects = array.map((option) => {
        const a = allData.map((item) => {
          return item[option] ? item[option] : 0;
        });
        return {
          // name: newOptionsObject[option],
          name: chartValue,
          data: a,
        };
      });

      const modalSeriesObjectArray = modalSeriesObjects.filter((item) => {
        return item !== undefined;
      });

      // console.log("modalSeriesObjectArray", modalSeriesObjectArray);

      //dates for x-axis

      const smallChartDates = smallChartData.map((item) => {
        // return `${item.date.substr(0, 4)}`;
        const a = new Date(item.date);
        const b = a.toUTCString("en-US");
        const date = b.toString();
        return `${date.substring(7, 12)}' ${date.substring(14, 16)}`;
      });

      const allDates = allData.map((item) => {
        const a = new Date(item.date);
        const b = a.toUTCString("en-US");
        const date = b.toString();
        return `${date.substring(7, 12)}' ${date.substring(14, 16)}`;
      });

      // const allDatesMax = allData.map((item) => {
      //   return `${item.date.substring(0, 4)}`;
      // });

      const allDatesDaily = allData.map((item) => {
        const a = new Date(item.date);
        const b = a.toUTCString("en-US");
        const date = b.toString();
        return `${date.substring(5, 7)}, ${date.substring(
          7,
          12
        )}' ${date.substring(14, 16)}`;
      });

      setOptions({
        markers: {
          strokeColors: chartValue == "close" ? "#43be29" : "#ff6962",
          colors: chartValue == "close" ? "#43be29" : "#ff6962",
        },
        grid: {
          borderColor: darkMode ? "#3d3d3d" : "#ccc",
          row: {
            colors: darkMode ? undefined : ["#fff"],
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          theme: darkMode ? "dark" : "light",
          y: {
            title: {
              formatter: (seriesName) =>
                chartValue == "close" ? "Price" : "Market Cap",
            },
            formatter:
              chartValue == "close" ? (val) => val.toFixed(2) : undefined,
          },
        },
        chart: {
          type: "area",
          toolbar: {
            show: false,
            // offsetX: -20,
            // offsetY: -50,
            // tools: {
            //   download: false,
            //   selection: false,
            //   zoom: true,
            //   zoomin: true,
            //   zoomout: true,
            //   pan: false,
            //   reset: true | '<img src="/static/icons/reset.png" width="20">',
            // },
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: color,
          curve: "straight",
        },
        fill: {
          colors: color,
        },
        dataLabels: {
          enabled: false,
        },
        labels: smallChartDates,
        xaxis: {
          type: "category",
          tooltip: {
            enabled: false,
          },
          labels: {
            style: {
              fontFamily: "Roboto-Regular",
              colors: darkMode ? "#fff" : "#000",
            },
            rotate: -45,
            rotateAlways: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
            // formatter: function (val) {
            //   return period == "annual"
            //     ? val
            //     : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
            // },
          },
          // categories: period == "annual" ? annualDates : quarterDates,
          tickAmount: 16,
        },
        yaxis: {
          tickAmount: 5,
          tooltip: {
            enabled: false,
          },
          labels: {
            formatter: function (value) {
              let v = parseFloat(value);
              let unit = "";
              if (v >= 1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v >= 1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v >= 1000) {
                v = v / 1000;
                unit = "K";
              } else if (v < 0) {
                if (v <= -1000000000) {
                  v = v / 1000000000;
                  unit = "B";
                } else if (v <= -1000000) {
                  v = v / 1000000;
                  unit = "M";
                } else if (v <= -1000) {
                  v = v / 1000;
                  unit = "K";
                }
              }

              return currency + v.toString().substring(0, 4) + unit;
            },
            style: {
              fontSize: "14px",
              // fontWeight: "bold",
              fontFamily: "Roboto-Regular",
              colors: darkMode ? "#fff" : "#000",
            },
          },
        },
      });
      setModalOptions({
        markers: {
          strokeColors: chartValue == "close" ? "#43be29" : "#ff6962",
          colors: chartValue == "close" ? "#43be29" : "#ff6962",
        },
        grid: {
          borderColor: darkMode ? "#3d3d3d" : "#ccc",
          row: {
            colors: darkMode ? undefined : ["#fff"],
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          theme: darkMode ? "dark" : "light",
          y: {
            title: {
              formatter: (seriesName) =>
                chartValue == "close" ? "Price" : "Market Cap",
            },
            formatter:
              chartValue == "close" ? (val) => val.toFixed(2) : undefined,
          },
        },
        chart: {
          type: "area",
          toolbar: {
            show: false,
            // offsetX: -20,
            // offsetY: 0,
            // tools: {
            //   download: false,
            //   selection: false,
            //   zoom: true,
            //   zoomin: true,
            //   zoomout: true,
            //   pan: false,
            //   reset: true | '<img src="/static/icons/reset.png" width="20">',
            // },
          },
        },
        // stroke: {
        //   show: true,
        //   width: 2,
        //   colors: color,
        //   curve: "straight",
        // },
        fill: {
          colors: color,
        },
        dataLabels: {
          enabled: false,
        },
        labels:
          filterValue == "7d" || filterValue == "1m" ? allDatesDaily : allDates,
        xaxis: {
          type: "category",
          tooltip: {
            enabled: false,
          },
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              fontFamily: "Roboto-Regular",
              colors: darkMode ? "#fff" : "#000",
            },
            rotate: -45,
            rotateAlways: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
            // formatter:
            //   filterValue == "max"
            //     ? function (val) {
            //         // console.log(dayjs(val).quarter());
            //         // return period == "annual"
            //         //   ? dayjs(val).format("YYYY")
            //         //   : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
            //         return dayjs(val).format("YYYY");
            //       }
            //     : undefined,
          },
          tickAmount: 60,
        },
        yaxis: {
          tickAmount: 6,
          tooltip: {
            enabled: false,
          },
          labels: {
            formatter: function (value) {
              let v = parseFloat(value);

              let unit = "";
              if (v >= 1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v >= 1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v >= 1000) {
                v = v / 1000;
                unit = "K";
              } else if (v < 0) {
                if (v <= -1000000000) {
                  v = v / 1000000000;
                  unit = "B";
                } else if (v <= -1000000) {
                  v = v / 1000000;
                  unit = "M";
                } else if (v <= -1000) {
                  v = v / 1000;
                  unit = "K";
                }
              }

              return currency + v.toString().substring(0, 4) + unit;
            },
            style: {
              fontSize: "14px",
              fontFamily: "Roboto-Regular",
              colors: darkMode ? "#fff" : "#000",
            },
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: color,
          curve: "smooth",
        },
        fill: {
          colors: color,
        },
        // legend: {
        //   position: "top",
        //   showForSingleSeries: true,
        //   markers: {
        //     width: 30,
        //     height: 12,
        //     radius: 0,
        //     fillColors: color,
        //   },
        // },
      });

      setSmallChartSeries(smallChartSeriesObjectArray);
      setModalSeries(modalSeriesObjectArray);
      setVisible(true);
    }
  }, [chartValue, smallChartData, allData, darkMode]);

  return (
    <>
      {visible ? (
        <div>
          {smallDisplay && visible ? (
            <div>
              <div className="flex flex-col">
                <div className="flex flex-row w-full">
                  <div className="flex flex-row w-[98%] mb-2">
                    <p className="ml-5 font-semibold">
                      {chartValue == "close" ? price : marketCap}
                    </p>
                  </div>
                  <div
                    className="flex justify-end cursor-pointer"
                    onClick={showModal}
                  >
                    <ExpandAltOutlined
                      style={{
                        fontSize: "18px",
                        color: darkMode ? "#6e8192" : "#3B3B3B",
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-center items-center">
                  <div
                    className="flex flex-row cursor-pointer mr-10"
                    onClick={() => {
                      setSmallFlag(!smallFlag);
                      setModalFlag(!modalFlag);
                      setColor(["#43be29"]);
                      setChartValue("close");
                    }}
                  >
                    <div className="block h-3 w-8 bg-[#43be29] mr-2 ml-14 rounded-sm"></div>
                    {priceNull == true ? (
                      <s className="text-xs">Price</s>
                    ) : (
                      <p className="text-xs">Price</p>
                    )}
                  </div>

                  <div
                    className="flex flex-row cursor-pointer"
                    onClick={() => {
                      setSmallFlag(!smallFlag);
                      setModalFlag(!modalFlag);
                      setColor(["#ff6962"]);
                      setChartValue("marketCap");
                    }}
                  >
                    <div className="block h-3 w-8 bg-[#ff6962] mr-2 rounded-sm"></div>
                    {mcNull == true ? (
                      <s className="mr-5 text-xs">MarketCap</s>
                    ) : (
                      <p className="mr-5 text-xs">MarketCap</p>
                    )}
                  </div>
                </div>

                <div className="chart">
                  <Chart
                    series={smallChartSeries}
                    options={options}
                    type="area"
                    height={230}
                    width={isMobile ? 220 : 450}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex w-[27rem] h-[12rem] justify-center items-center">
              Loading...
            </div>
          )}
          <CustomModal
            visible={isModalOpen}
            onCancel={handleCancel}
            display={dispaly}
            setDisplay={setDisplay}
            isModalOpen={isModalOpen}
          >
            <div className="flex flex-col">
              {/* price or market cap value and filter both */}
              <div className="flex flex-row ">
                {/* price or market cap value */}
                <div className="flex flex-row w-[10%] mb-2">
                  <p
                    className={
                      darkMode
                        ? "ml-5 font-bold text-lg text-white"
                        : "ml-5 font-bold text-lg text-black"
                    }
                  >
                    {chartValue == "close" ? price : marketCap}
                  </p>
                </div>
                {/* price and mc filter */}

                <div className="flex flex-row w-[75%] justify-center items-center">
                  <div
                    className="flex flex-row cursor-pointer mr-10 text-sm"
                    onClick={() => {
                      setDisplay(false);
                      setColor(["#43be29"]);
                      setChartValue("close");
                      if (filterValue == "1m" || filterValue == "7d") {
                        setAllData(allDataP);
                        setDisplay(true);
                      } else {
                        setSmallFlag(!smallFlag);
                        setModalFlag(!modalFlag);
                      }
                    }}
                  >
                    <div className="block h-3 w-8 bg-[#43be29] mr-2 ml-14 rounded-sm mt-1"></div>
                    {priceNull == true ? (
                      <s className={darkMode ? "text-white" : "text-black"}>
                        Price
                      </s>
                    ) : (
                      <p className={darkMode ? "text-white" : "text-black"}>
                        Price
                      </p>
                    )}
                  </div>

                  <div
                    className="flex flex-row cursor-pointer mr-5 text-sm"
                    onClick={() => {
                      setDisplay(false);
                      setColor(["#ff6962"]);
                      setChartValue("marketCap");
                      if (filterValue == "1m" || filterValue == "7d") {
                        setAllData(allDataMC);
                        setDisplay(true);
                      } else {
                        setSmallFlag(!smallFlag);
                        setModalFlag(!modalFlag);
                      }
                    }}
                  >
                    <div className="block h-3 w-8 bg-[#ff6962] mr-2 rounded-sm mt-1"></div>
                    {mcNull == true ? (
                      <s className={darkMode ? "text-white" : "text-black"}>
                        MarketCap
                      </s>
                    ) : (
                      <p className={darkMode ? "text-white" : "text-black"}>
                        MarketCap
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* years filters */}
              <div className="flex flex-row justify-center items-center mt-5 ml-6 cursor-pointer">
                <div
                  className={
                    darkMode
                      ? filterValue == "7d"
                        ? "w-24 h-9 border-white bg-white text-black font-bold border-2 rounded-l-md "
                        : "w-24 h-9 border-white text-white border-2 rounded-l-md"
                      : filterValue == "7d"
                      ? "w-24 h-9 bg-gray-300 border-gray-700 border-2 rounded-l-md"
                      : "w-24 h-9 border-gray-700 border-2 rounded-l-md"
                  }
                  onClick={() => {
                    setDisplay(false);
                    dailyRequest(filter("7", "7d", "days"));
                    // filter("7", "7d", "days");
                  }}
                >
                  <p className="flex justify-center items-center font-semibold text-sm mt-1">
                    1 Week
                  </p>
                </div>
                <div
                  className={
                    darkMode
                      ? filterValue == "1m"
                        ? "w-24 h-9 border-white bg-white text-black font-bold border-r-2 border-t-2 border-b-2 "
                        : "w-24 h-9 border-white text-white border-r-2 border-t-2 border-b-2 "
                      : filterValue == "1m"
                      ? "w-24 h-9 border-gray-700 bg-gray-300 border-r-2 border-t-2 border-b-2 "
                      : "w-24 h-9 border-gray-700 border-r-2 border-t-2 border-b-2 "
                  }
                  onClick={() => {
                    setDisplay(false);
                    dailyRequest(filter("1", "1m", "month"));
                    // filter("1", "1m", "month");
                  }}
                >
                  <p className="flex justify-center items-center font-semibold text-sm mt-1">
                    1 Month
                  </p>
                </div>
                <div
                  className={
                    darkMode
                      ? filterValue == "3m"
                        ? "w-24 h-9 border-white bg-white text-black font-bold border-r-2 border-t-2 border-b-2 "
                        : "w-24 h-9 border-white text-white border-r-2 border-t-2 border-b-2 "
                      : filterValue == "3m"
                      ? "w-24 h-9 border-gray-700 bg-gray-300 border-r-2 border-t-2 border-b-2 "
                      : "w-24 h-9 border-gray-700 border-r-2 border-t-2 border-b-2 "
                  }
                  onClick={() => {
                    dailyRequest("false");
                    filter("3", "3m", "month");
                  }}
                >
                  <p className="flex justify-center items-center font-semibold text-sm mt-1">
                    3 Month
                  </p>
                </div>
                <div
                  className={
                    darkMode
                      ? filterValue == "6m"
                        ? "w-24 h-9 border-white bg-white text-black font-bold border-r-2 border-t-2 border-b-2 "
                        : "w-24 h-9 border-white text-white border-r-2 border-t-2 border-b-2 "
                      : filterValue == "6m"
                      ? "w-24 h-9 border-gray-700 bg-gray-300 border-r-2 border-t-2 border-b-2 "
                      : "w-24 h-9 border-gray-700 border-r-2 border-t-2 border-b-2 "
                  }
                  onClick={() => {
                    dailyRequest("false");
                    filter("6", "6m", "month");
                  }}
                >
                  <p className="flex justify-center items-center font-semibold text-sm mt-1">
                    6 Month
                  </p>
                </div>
                <div
                  className={
                    darkMode
                      ? filterValue == "ytd"
                        ? "w-24 h-9 border-white bg-white text-black font-bold border-r-2 border-t-2 border-b-2 "
                        : "w-24 h-9 border-white text-white border-r-2 border-t-2 border-b-2 "
                      : filterValue == "ytd"
                      ? "w-24 h-9 border-gray-700 bg-gray-300 border-r-2 border-t-2 border-b-2 "
                      : "w-24 h-9 border-gray-700 border-r-2 border-t-2 border-b-2 "
                  }
                  onClick={() => {
                    dailyRequest("false");
                    filter("0", "ytd", "year");
                  }}
                >
                  <p className="flex justify-center items-center font-semibold text-sm mt-1">
                    YTD
                  </p>
                </div>
                <div
                  className={
                    darkMode
                      ? filterValue == "1y"
                        ? "w-24 h-9 border-white bg-white text-black font-bold border-r-2 border-t-2 border-b-2"
                        : "w-24 h-9 border-white text-white border-r-2 border-t-2 border-b-2"
                      : filterValue == "1y"
                      ? "w-24 h-9 bg-gray-300 border-gray-700 border-r-2 border-t-2 border-b-2"
                      : "w-24 h-9 border-gray-700 border-r-2 border-t-2 border-b-2"
                  }
                  onClick={() => {
                    dailyRequest("false");
                    filter("1", "1y", "year");
                  }}
                >
                  <p className="flex justify-center items-center font-semibold text-sm mt-1">
                    1 year
                  </p>
                </div>
                <div
                  className={
                    darkMode
                      ? filterValue == "5y"
                        ? "w-24 h-9 border-white bg-white text-black font-bold border-r-2 border-t-2 border-b-2 "
                        : "w-24 h-9 border-white text-white border-r-2 border-t-2 border-b-2 "
                      : filterValue == "5y"
                      ? "w-24 h-9 border-gray-700 bg-gray-300 border-r-2 border-t-2 border-b-2 "
                      : "w-24 h-9 border-gray-700 border-r-2 border-t-2 border-b-2 "
                  }
                  onClick={() => {
                    dailyRequest("false");
                    filter("5", "5y", "year");
                  }}
                >
                  <p className="flex justify-center items-center font-semibold text-sm mt-1">
                    5 year
                  </p>
                </div>
                <div
                  className={
                    darkMode
                      ? filterValue == "10y"
                        ? "w-24 h-9 border-white bg-white text-black font-bold border-t-2 border-b-2 "
                        : "w-24 h-9 border-white text-white border-t-2 border-b-2 "
                      : filterValue == "10y"
                      ? "w-24 h-9 bg-gray-300 border-gray-700 border-t-2 border-b-2 "
                      : "w-24 h-9 border-gray-700 border-t-2 border-b-2 "
                  }
                  onClick={() => {
                    dailyRequest("false");
                    filter("10", "10y", "year");
                  }}
                >
                  <p className="flex justify-center items-center font-semibold text-sm mt-1">
                    10 year
                  </p>
                </div>
                <div
                  className={
                    darkMode
                      ? filterValue == "max"
                        ? "w-24 h-9 border-white bg-white text-black font-bold border-2 rounded-r-md "
                        : "w-24 h-9 border-white text-white border-2 rounded-r-md "
                      : filterValue == "max"
                      ? "w-24 h-9 border-gray-700 bg-gray-300 border-2 rounded-r-md "
                      : "w-24 h-9 border-gray-700 border-2 rounded-r-md "
                  }
                  onClick={() => {
                    dailyRequest("false");
                    filter("0", "max", "year");
                  }}
                >
                  <p className="flex justify-center items-center font-semibold text-sm mt-1">
                    All
                  </p>
                </div>
              </div>

              {/* chart */}
              <div className="chart">
                <Chart
                  options={modalOptions}
                  series={modalSeries}
                  type="area"
                  height={470}
                  width={isMobile ? 300 : 1250}
                />
              </div>
              {chartValue == "close" ? (
                <Cagr
                  cagr={cagrPrice}
                  bg="#e0f4dc"
                  text="#4F7942"
                  className="flex flex-row p-1 px-2 rounded-md"
                />
              ) : (
                <Cagr
                  cagr={cagrMC}
                  bg="#ffe4e4"
                  text="#ff6962"
                  className="flex flex-row p-1 px-2 rounded-md"
                />
              )}
            </div>
          </CustomModal>
        </div>
      ) : (
        <div className="flex w-[27rem] h-[12rem] justify-center items-center">
          Loading...
        </div>
      )}
    </>
  );
}

export default AreaChart;
