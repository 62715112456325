import React from "react";
import { Spin } from "antd";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useVerifyAccessToken } from "../Hooks/auth.hook";

const Protected = ({ Component }) => {
  const navigate = useNavigate();

  const { data, status, remove } = useVerifyAccessToken();
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    if (status == "success" && data.status == 200) {
      setIsAuthenticated(true);
    } else if (status == "success" && data.status == 401) {
      navigate("/");
    }
  }, [status, data]);

  useEffect(() => {
    return () => {
      remove();
    };
  }, []);

  return (
    <div>
      {isAuthenticated !== null && isAuthenticated == true ? (
        <Component />
      ) : (
        <Spin />
      )}
    </div>
  );
};

export default Protected;
