import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Spin } from "antd";

import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
// import loginLottie from "../../assests/login.json";
// import AnimationLottie from "../../common/lottieHelper/AnimationLottie";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import AnimationLottie from "../../lottieHelper/AnimationLottie";
import loginLottie from "../../../assests/login.json";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {useUpdatePassword} from '../../../Hooks/auth.hook'


// const SignInSchema = Yup.object().shape({

//     password: Yup.string()
//       .required("Password is required")
//       .min(4, "Password must be at least 4 characters"),
//       confirmpassword: Yup.string()
//       .required("Password is required")
//       .min(4, "Password must be at least 4 characters"),
//   });
  const SignInSchema = Yup.object({
    password: Yup
      .string()
      .required('Password is required')
      .min(5, 'Your password is too short.'),
    confirmpassword: Yup
      .string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
    });
    

const ResetPassword = ()=>{
    const location = useLocation()
    const email = location.state
    const verified = location.state ? true : false
  const [showLoader, setShowLoading] = useState(false);
  const [passwordMatch , setPasswordMatch] = useState()

  const { mutate, status, isError, data } = useUpdatePassword()
  const navigate = useNavigate()

  useEffect(()=>{
        if(!verified)
        navigate('/')
  },[])
  useEffect(() => {
    if (status == "success") {
      console.log(data.status);
      if (data.data.status == 200) {
        toast.success("Updated : Redirecting to Login", {
          autoClose: 1000,
          onClose:()=>navigate('/')
        });
      


      } else if (data.data.status == 403) {
        toast.error(data.data.message, {
          autoClose: 1500,
        });
   
      }
      else if(data.status == 403) {
        toast.error("something went wrong", {
          autoClose: 1500,
        });
        // setShowLoading(false)
      }
    }
  }, [data, status]);
return (
    <div className="h-screen flex flex-row justify-center ">
        <ToastContainer />
      <Formik
        initialValues={{
          password: "",
          confirmpassword: "",
        }}
        
        validationSchema={SignInSchema}
        onSubmit={(values) => {
          console.log(values);

          mutate({email:email, password : values.password})
        
  
        }}
      >
        {({ errors, touched,values }) => (
          <>
            <Form className="bg-white py-9 px-10  w-full md:w-[50%] flex flex-col justify-center items-center">
              <div>
               
                <p className="text-center mb-4">Enter Your New Password</p>
                <div className="relative text-gray-800 mt-4">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FontAwesomeIcon
                      icon={faLock}
                      className="ml-2 mt-2 w-4 h-4 "
                    />
                  </span>
                  <Field
                    id="password"
                    name="password"
                    placeholder="Password"
                    type="password"
                    className={`w-full py-2 pl-10  mt-2 border ${
                      errors.password && touched.password
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                </div>

                {errors.password && touched.password ? (
                  <div className="text-red-500">{errors.password}</div>
                ) : null}

                <div className="relative text-gray-800 mt-4">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <FontAwesomeIcon
                      icon={faLock}
                      className="ml-2 mt-2 w-4 h-4 "
                    />
                  </span>
                  <Field
                    id="confirmpassword"
                    name="confirmpassword"
                    placeholder="Confirm Password"
                    type="password"
                    className={`w-full py-2 pl-10  mt-2 border ${
                      errors.confirmpassword && touched.confirmpassword
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                </div>

                {errors.confirmpassword && touched.confirmpassword ? (
                  <div className="text-red-500">{errors.confirmpassword}</div>
                ) : null}


                <div className="">
                  <button
                    type="submit"
                    className={` text-white py-2 mt-8 px-12 sm:px-28 rounded-lg  ${
                      showLoader
                        ? "bg-transparent border border-blue"
                        : (!values.confirmpassword && errors.confirmpassword) && "bg-blue-500 hover:bg-blue-600"
                    } ${ !values.confirmpassword || errors.confirmpassword ? "bg-[#d0cdcd] border border-blue" :"bg-blue-500 hover:bg-blue-600" }`  }
                    disabled={!values.confirmpassword || errors.confirmpassword}
                  >
                    <div>
                      {showLoader ? (
                        <div className="d-flex justify-center">
                          <Spin />
                        </div>
                      ) : (
                        "UPDATE"
                      )}
                    </div>
                  </button>
                </div>
               
              </div>
            </Form>
            {/* <div className="w-[50%] hidden  md:flex justify-center items-center bg-gradient-to-r from-[#C0DBEA] via-30% to-[#FCC8D1] to-90% ">
              <AnimationLottie animationPath={loginLottie} />
            </div> */}
          </>
        )}
      </Formik>
      <div className="w-[50%] hidden  md:flex justify-center items-center bg-gradient-to-r from-[#C0DBEA] via-30% to-[#FCC8D1] to-90% ">
              <AnimationLottie animationPath={loginLottie} />
            </div>
       
    </div>
    )
}
export default ResetPassword