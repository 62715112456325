import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import "../style/style.css";
import { ExpandAltOutlined } from "@ant-design/icons";
import "../style/style.css";
import CustomModal from "../components/CustomModal";
import getSymbolFromCurrency from "currency-symbol-map";
import { ThemeContext } from "../../../Context/ThemeContext";

function Graph11({
  graphData,
  fd,
  qd,
  period,
  years,
  // unit,
  optionsArray,
  currencyText,
}) {
  const { darkMode } = useContext(ThemeContext);

  const [value, setValue] = useState("fcfYield");
  const [smallChartData, setSmallChartData] = useState(
    period == "annual" ? fd : qd
  );
  const [allData, setAllData] = useState(graphData);

  const [color, setColor] = useState(
    value == "peRatio"
      ? ["#f1ce63"]
      : value == "pbRatio"
      ? ["#2E8BC0"]
      : value == "roe"
      ? ["#ff6962"]
      : value == "returnOnTangibleAssets"
      ? ["#549e39"]
      : ["#499894"]
  );
  const [currency, setCurrency] = useState(getSymbolFromCurrency(currencyText));

  const [visible, setVisible] = useState(null);
  const [options, setOptions] = useState({});
  const [modalOptions, setModalOptions] = useState({});

  const [smallChartSeries, setSmallChartSeries] = useState([]);
  const [modalSeries, setModalSeries] = useState([]);

  const [smallChartSingle, setSmallChartSingle] = useState([]);
  const [modalSingle, setModalSingle] = useState([]);

  const [activePeriodTab, setActivePeriodTab] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dispaly, setDisplay] = useState(false);
  const [smallDisplay, setSmallDisplay] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDisplay(false);
  };

  useEffect(() => {
    setSmallDisplay(false);

    smallChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
    allData.sort((a, b) => new Date(a.date) - new Date(b.date));

    // let denominator;
    // if (unit == " th") {
    //   denominator = 1000;
    // } else if (unit == " m") {
    //   denominator = 1000000;
    // } else if (unit == " B") {
    //   denominator = 1000000000;
    // }

    setTimeout(() => setSmallDisplay(true), 10);

    const newOptionsObject = {
      peRatio: "peRatio",
      pbRatio: "pbRatio",
      roe: "roe",
      returnOnTangibleAssets: "returnOnTangibleAssets",
      fcfYield: "fcfYield",
    };

    //for series

    let smallChartSeriesObjects = [];

    smallChartSeriesObjects = optionsArray.map((option) => {
      if (option !== "date") {
        const a = smallChartData.map((item) => {
          // return item[option] ? item[option] : 0;
          return {
            x: item.date,
            y:
              value == "roe" || value == "returnOnTangibleAssets"
                ? item[option]
                  ? item[option] * 100
                  : 0
                : item[option]
                ? item[option]
                : 0,
          };
        });
        return {
          name: newOptionsObject[option],
          data: a,
        };
      }
    });

    const smallChartSeriesObjectArray = smallChartSeriesObjects.filter(
      (item) => {
        return item !== undefined;
      }
    );

    // console.log("smallChartSeriesObjectArray", smallChartSeriesObjectArray);

    //for modal series

    let modalSeriesObject = [];

    modalSeriesObject = optionsArray.map((option) => {
      if (option !== "date") {
        const a = allData.map((item) => {
          return {
            x: `${item.date.substr(0, 4)}/${item.date.substr(
              5,
              2
            )}/${item.date.substr(8, 2)}`,
            y:
              value == "roe" || value == "returnOnTangibleAssets"
                ? item[option]
                  ? item[option] * 100
                  : 0
                : item[option]
                ? item[option]
                : 0,
          };
          // return item[option] ? item[option] : 0;
        });
        return {
          name: newOptionsObject[option],
          data: a,
        };
      }
    });

    const modalSeriesObjectArray = modalSeriesObject.filter((item) => {
      return item !== undefined;
    });

    // console.log("ModalSeriesObjectArray", modalSeriesObjectArray);

    //for x-axis or dates

    const smallChartDates = smallChartData.map((item) => {
      return `${item.date.substr(0, 4)}`;
    });

    const allDates = allData.map((item) => {
      return `${item.date.substr(0, 4)}/${item.date.substr(
        5,
        2
      )}/${item.date.substr(8, 2)}`;
    });

    setOptions({
      tooltip: {
        marker: {
          // show: true,
          show: false,
          fillColors: color,
        },
        theme: darkMode ? "dark" : "light",
      },
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "50%"
                : "70%"
              : years == "10"
              ? period == "annual"
                ? "60%"
                : "70%"
              : period == "annual"
              ? "60%"
              : "70%",
          barHeight: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width:
          years == "5"
            ? period == "annual"
              ? 4
              : 6
            : years == "10"
            ? period == "annual"
              ? 4
              : 8
            : period == "annual"
            ? 4
            : 4,
        colors: color,
      },
      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      xaxis: {
        forceNiceScale: true,
        type: "category",
        labels: {
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          rotate: -45,
          // rotateAlways: years == "5" ? false : true,
          rotateAlways: true,
          style: {
            fontSize: "12px",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        categories: smallChartDates,
        tickPlacement: "on",
        tickAmount: 24,
      },
      yaxis: {
        forceNiceScale: true,
        // max:
        labels: {
          formatter: function (val) {
            let v = parseFloat(val);
            let unit = "";

            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return value == "roe" ||
              value == "returnOnTangibleAssets" ||
              value == "fcfYield"
              ? v.toString().substring(0, 4) + "%"
              : v.toString().substring(0, 4) + unit;
          },
          style: {
            fontSize: "14px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        tickAmount: 7,
      },
      fill: {
        opacity: 1,
        colors: color,
      },
    });
    setModalOptions({
      tooltip: {
        marker: {
          // show: true,
          show: false,
          fillColors: color,
        },
        theme: darkMode ? "dark" : "light",
      },
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "30%"
                : "50%"
              : years == "10"
              ? period == "annual"
                ? "50%"
                : "70%"
              : period == "annual"
              ? "60%"
              : "70%",
          endingShape: "rounded",
          barHeight: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width:
          years == "5"
            ? period == "annual"
              ? 4
              : 4
            : years == "10"
            ? period == "annual"
              ? 4
              : 4
            : period == "annual"
            ? 4
            : 4,
        colors: color,
      },
      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      xaxis: {
        forceNiceScale: true,
        type: "category",
        labels: {
          // hideOverlappingLabels: true,
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          rotate: -45,
          // rotateAlways: years == "5" ? false : true,
          rotateAlways: true,
          style: {
            fontSize: "14px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        categories: allDates,
        tickPlacement: "on",
        tickAmount: period == "annual" ? 45 : 60,
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            let v = parseFloat(val);
            let unit = "";

            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return value == "roe" ||
              value == "returnOnTangibleAssets" ||
              value == "fcfYield"
              ? v.toString().substring(0, 4) + "%"
              : v.toString().substring(0, 4) + unit;
          },
          style: {
            fontSize: "14px",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        tickAmount: 8,
      },
      fill: {
        opacity: 1,
        colors: color,
      },
    });

    const smallChartselectedObject = smallChartSeriesObjectArray.filter(
      (item) => {
        if (item.name == value) {
          return item;
        }
      }
    );
    const modalChartselectedObject = modalSeriesObjectArray.filter((item) => {
      if (item.name == value) {
        return item;
      }
    });

    setSmallChartSingle(smallChartselectedObject);
    setModalSingle(modalChartselectedObject);

    setSmallChartSeries(smallChartSeriesObjectArray);
    setModalSeries(modalSeriesObjectArray);
    setVisible(true);
  }, [value, darkMode]);

  const checkSmall = (val) => {
    // console.log(val);
    const result = smallChartSeries.map((item) => {
      if (item.name == val && item.data.length == 0) {
        return true;
      } else if (item.name == val && item.data.length > 0) {
        return false;
      }
    });

    const result2 = result.filter((item) => item !== undefined);

    // console.log(smallChartSeries, result);

    return result2[0];
  };

  const checkModal = (val) => {
    // console.log(val);
    const result = modalSeries.map((item) => {
      if (item.name == val && item.data.length == 0) {
        return true;
      } else if (item.name == val && item.data.length > 0) {
        return false;
      }
    });

    const result2 = result.filter((item) => item !== undefined);

    // console.log(smallChartSeries, result);

    return result2[0];
  };

  return (
    <>
      {visible ? (
        <div>
          {smallDisplay ? (
            <div className="flex flex-col">
              <div
                className="flex justify-end cursor-pointer"
                onClick={showModal}
              >
                <ExpandAltOutlined
                  style={{
                    fontSize: "18px",
                    color: darkMode ? "#6e8192" : "#3B3B3B",
                  }}
                />
              </div>

              <div className="flex flex-row justify-center items-center pt-3">
                <div
                  className="flex flex-row cursor-pointer mr-3 ml-10"
                  onClick={() => {
                    setColor(["#499894"]);
                    setValue("fcfYield");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#499894] mr-2 rounded-sm"></div>
                  {checkSmall("fcfYield") == true ? (
                    <s className="text-xs">FCF Yield</s>
                  ) : (
                    <p className="text-xs">FCF Yield</p>
                  )}
                </div>

                <div
                  className="flex flex-row cursor-pointer mr-3"
                  onClick={() => {
                    setColor(["#549e39"]);
                    setValue("returnOnTangibleAssets");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#549e39] mr-2 rounded-sm"></div>
                  {checkSmall("returnOnTangibleAssets") == true ? (
                    <s className="text-xs">ROTA</s>
                  ) : (
                    <p className="text-xs">ROTA</p>
                  )}
                </div>

                <div
                  className="flex flex-row cursor-pointer mr-3"
                  onClick={() => {
                    setColor(["#f1ce63"]);
                    setValue("peRatio");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#f1ce63] mr-2 rounded-sm"></div>
                  {checkSmall("peRatio") == true ? (
                    <s className="text-xs">P/E</s>
                  ) : (
                    <p className="text-xs">P/E</p>
                  )}
                </div>

                <div
                  className="flex flex-row cursor-pointer mr-3"
                  onClick={() => {
                    setColor(["#2E8BC0"]);
                    setValue("pbRatio");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#2E8BC0] mr-2 rounded-sm"></div>
                  {checkSmall("pbRatio") == true ? (
                    <s className="text-xs">P/B</s>
                  ) : (
                    <p className="text-xs">P/B</p>
                  )}
                </div>

                <div
                  className="flex flex-row cursor-pointer mr-3"
                  onClick={() => {
                    setColor(["#ff6962"]);
                    setValue("roe");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#ff6962] mr-2 rounded-sm"></div>
                  {checkSmall("roe") == true ? (
                    <s className="text-xs">ROE</s>
                  ) : (
                    <p className="text-xs">ROE</p>
                  )}
                </div>
              </div>

              <div className="chart">
                <ReactApexChart
                  options={options}
                  series={smallChartSingle}
                  type="bar"
                  height={250}
                  width={isMobile ? 220 : 450}
                />
              </div>
            </div>
          ) : (
            <div className="flex w-[27rem] h-[12rem] justify-center items-center">
              Loading...
            </div>
          )}
          <CustomModal
            visible={isModalOpen}
            onCancel={handleCancel}
            display={dispaly}
            setDisplay={setDisplay}
            isModalOpen={isModalOpen}
          >
            <div className="flex flex-col">
              <div className="flex flex-row justify-center items-center pt-3">
                <div
                  className="flex flex-row cursor-pointer mr-10 ml-10 text-xs"
                  onClick={() => {
                    setColor(["#499894"]);
                    setValue("fcfYield");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#499894] mr-2 rounded-sm"></div>
                  {checkModal("fcfYield") == true ? (
                    <s className={darkMode ? "text-white" : "text-black"}>
                      FCF Yield
                    </s>
                  ) : (
                    <p className={darkMode ? "text-white" : "text-black"}>
                      FCF Yield
                    </p>
                  )}
                </div>

                <div
                  className="flex flex-row cursor-pointer mr-10 text-xs"
                  onClick={() => {
                    setColor(["#549e39"]);
                    setValue("returnOnTangibleAssets");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#549e39] mr-2 rounded-sm"></div>
                  {checkModal("returnOnTangibleAssets") == true ? (
                    <s className={darkMode ? "text-white" : "text-black"}>
                      ROTA
                    </s>
                  ) : (
                    <p className={darkMode ? "text-white" : "text-black"}>
                      ROTA
                    </p>
                  )}
                </div>

                <div
                  className="flex flex-row cursor-pointer mr-10 text-xs"
                  onClick={() => {
                    setColor(["#f1ce63"]);
                    setValue("peRatio");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#f1ce63] mr-2 rounded-sm"></div>
                  {checkModal("peRatio") == true ? (
                    <s className={darkMode ? "text-white" : "text-black"}>
                      P/E
                    </s>
                  ) : (
                    <p className={darkMode ? "text-white" : "text-black"}>
                      P/E
                    </p>
                  )}
                </div>

                <div
                  className="flex flex-row cursor-pointer mr-10 text-xs"
                  onClick={() => {
                    // setOptionsArray(["date", "pbRatio"]);
                    setColor(["#2E8BC0"]);
                    setValue("pbRatio");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#2E8BC0] mr-2 rounded-sm"></div>
                  {checkModal("pbRatio") == true ? (
                    <s className={darkMode ? "text-white" : "text-black"}>
                      P/B
                    </s>
                  ) : (
                    <p className={darkMode ? "text-white" : "text-black"}>
                      P/B
                    </p>
                  )}
                </div>

                <div
                  className="flex flex-row cursor-pointer mr-10 text-xs"
                  onClick={() => {
                    setColor(["#ff6962"]);
                    setValue("roe");
                  }}
                >
                  <div className="block h-3 w-8 bg-[#ff6962] mr-2 rounded-sm"></div>
                  {checkModal("roe") == true ? (
                    <s className={darkMode ? "text-white" : "text-black"}>
                      ROE
                    </s>
                  ) : (
                    <p className={darkMode ? "text-white" : "text-black"}>
                      ROE
                    </p>
                  )}
                </div>
              </div>
              <div className="chart">
                <ReactApexChart
                  options={modalOptions}
                  series={modalSingle}
                  type="bar"
                  height={530}
                  width={isMobile ? 300 : 1250}
                />
              </div>
            </div>
          </CustomModal>
        </div>
      ) : (
        <div className="flex w-[27rem] h-[12rem] justify-center items-center">
          Loading...
        </div>
      )}
    </>
  );
}

export default Graph11;
