import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import ReactApexChart from "react-apexcharts";
import "../style/style.css";
import { ExpandAltOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import CustomModal from "../components/CustomModal";
import Tabs from "../components/Tabs";
import getSymbolFromCurrency from "currency-symbol-map";
import { ThemeContext } from "../../../Context/ThemeContext";
import Cagr from "../components/Cagr";

dayjs.extend(quarterOfYear);

function Graph4({
  graphData,
  fd,
  qd,
  period,
  //  unit,
  years,
  optionsArray,
  currencyText,
  cagr,
}) {
  const { darkMode } = useContext(ThemeContext);
  const [smallChartData, setSmallChartData] = useState(
    period == "annual" ? fd : qd
  );
  const [allData, setAllData] = useState(graphData);
  // console.log(smallChartData);
  const [currency, setCurrency] = useState(getSymbolFromCurrency(currencyText));
  const [visible, setVisible] = useState(null);
  const [options, setOptions] = useState({});
  const [modalOptions, setModalOptions] = useState({});
  const [dispaly, setDisplay] = useState(false);
  const [smallDisplay, setSmallDisplay] = useState(false);

  const [smallChartSeries, setSmallChartSeries] = useState([]);
  const [modalSeries, setModalSeries] = useState([]);

  const [activePeriodTab, setActivePeriodTab] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDisplay(false);
  };
  useEffect(() => {
    setSmallDisplay(false);

    smallChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
    allData.sort((a, b) => new Date(a.date) - new Date(b.date));

    // let denominator;
    // if (unit == " th") {
    //   denominator = 1000;
    // } else if (unit == " m") {
    //   denominator = 1000000;
    // } else if (unit == " B") {
    //   denominator = 1000000000;
    // }

    setTimeout(() => setSmallDisplay(true), 10);

    //for series

    const newOptionsObject = {
      freeCashFlow: "FCF",
      stockBasedCompensation: "SBC",
    };

    const newOptionsEmptyObject = {
      freeCashFlow: "F̶C̶F̶",
      stockBasedCompensation: " ̶S̶B̶C",
    };

    //series
    const smallChartSeriesObjects = optionsArray.map((option) => {
      //old code

      // if (option !== "date") {
      //   const a = smallChartData.map((item) => {
      //     // return item[option] / denominator;
      //     return item[option] ? item[option] : 0;
      //   });
      //   return {
      //     name: newOptionsObject[option],
      //     data: a,
      //   };
      // }

      //new code

      if (option !== "date") {
        const a = smallChartData.map((item) => {
          // return item[option] && item[option] !== 0 ? item[option] : null;
          return {
            x: item.date,
            y: item[option] && item[option] !== 0 ? item[option] : null,
          };
        });
        const filtered = a.filter((item) => {
          return item.y !== null;
        });
        if (filtered.length > 0) {
          return {
            name: newOptionsObject[option],
            data: a,
          };
        } else {
          return {
            name: newOptionsEmptyObject[option],
            data: [],
          };
        }
      }
    });

    const smallChartSeriesObjectArray = smallChartSeriesObjects.filter(
      (item) => {
        return item !== undefined;
      }
    );

    // console.log("smallChartSeriesObjectArray", smallChartSeriesObjectArray);

    //modal series

    const modalSeriesObjects = optionsArray.map((option) => {
      //old code

      // if (option !== "date") {
      //   const a = allData.map((item) => {
      //     // return item[option] / denominator;
      //     return item[option] ? item[option] : 0;
      //   });
      //   return {
      //     name: newOptionsObject[option],
      //     data: a,
      //   };
      // }

      //new code

      if (option !== "date") {
        const a = allData.map((item) => {
          // return item[option] && item[option] !== 0 ? item[option] : null;
          return {
            x: item.date,
            y: item[option] && item[option] !== 0 ? item[option] : null,
          };
        });
        const filtered = a.filter((item) => {
          return item.y !== null;
        });
        if (filtered.length > 0) {
          return {
            name: newOptionsObject[option],
            data: a,
          };
        } else {
          return {
            name: newOptionsEmptyObject[option],
            data: [],
          };
        }
      }
    });

    const modalSeriesObjectArray = modalSeriesObjects.filter((item) => {
      return item !== undefined;
    });
    // console.log("modalSeriesObjectArray", modalSeriesObjectArray);

    //for x-axis or dates

    const smallChartDates = smallChartData.map((item) => {
      return `${item.date.substr(0, 4)}`;
    });

    const allDates = allData.map((item) => {
      return `${item.date.substr(0, 4)}/${item.date.substr(
        5,
        2
      )}/${item.date.substr(8, 2)}`;
    });

    setOptions({
      tooltip: {
        marker: {
          // show: true,
          show: false,
          fillColors: ["#feb019", "#40bd25"],
        },
        theme: darkMode ? "dark" : "light",
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "60%"
                : "60%"
              : years == "10"
              ? period == "annual"
                ? "70%"
                : "70%"
              : period == "annual"
              ? "80%"
              : "80%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width:
          years == "5"
            ? period == "annual"
              ? 5
              : 6
            : years == "10"
            ? period == "annual"
              ? 5
              : 5
            : period == "annual"
            ? 5
            : 5,
        colors: ["#feb019", "#40bd25"],
      },
      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      xaxis: {
        type: "category",
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          rotate: -45,
          // rotateAlways: years == "5" ? false : true,
          rotateAlways: true,
          showDuplicates: false,
          // offsetX: years == "5" ? 25 : 0,
          style: {
            // fontSize: period == "annual" ? "5px" : "10px",
            fontSize: "12px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        categories: smallChartDates,
        tickPlacement: "on",
        tickColor: 24,
      },
      yaxis: {
        tickAmount: 7,
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            let v = parseFloat(value);

            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }

            return currency + v.toString().substring(0, 4) + unit;
          },
          style: {
            fontSize: "14px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          colors: darkMode ? "#fff" : "#000",
        },
        showForSingleSeries: true,
        markers: {
          width: 30,
          height: 12,
          fillColors: ["#feb019", "#40bd25"],
        },
      },

      fill: {
        opacity: 1,
        colors: ["#feb019", "#40bd25"],
      },
    });
    setModalOptions({
      tooltip: {
        marker: {
          // show: true,
          show: false,
          fillColors: ["#feb019", "#40bd25"],
        },
        theme: darkMode ? "dark" : "light",
      },
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "20%"
                : "60%"
              : years == "10"
              ? period == "annual"
                ? "40%"
                : "70%"
              : period == "annual"
              ? "80%"
              : "80%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width:
          years == "5"
            ? period == "annual"
              ? 5
              : 6
            : years == "10"
            ? period == "annual"
              ? 5
              : 5
            : period == "annual"
            ? 4
            : 5,
        colors: ["#feb019", "#40bd25"],
      },
      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      xaxis: {
        type: "category",
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          rotate: -45,
          // rotateAlways: years == "5" ? false : true,
          rotateAlways: true,
          showDuplicates: false,
          // offsetX: years == "5" ? 55 : years == "10" ? 25 : 0,
          style: {
            fontSize: "14px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        categories: allDates,
        tickPlacement: "end",
        tickAmount: period == "annual" ? 45 : 70,
        // tickAmount:
        //   years == "5"
        //     ? period == "annual"
        //       ? 4
        //       : 20
        //     : years == "10"
        //     ? period == "annual"
        //       ? 9
        //       : 24
        //     : 24,
      },
      yaxis: {
        tickAmount: 8,
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }

            return currency + v.toString().substring(0, 4) + unit;
          },
          style: {
            fontSize: "14px",
            colors: darkMode ? "#fff" : "#000",
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          colors: darkMode ? "#fff" : "#000",
        },
        showForSingleSeries: true,
        markers: {
          width: 35,
          height: 12,
          fillColors: ["#feb019", "#40bd25"],
        },
      },
      fill: {
        opacity: 1,
        colors: ["#feb019", "#40bd25"],
      },
    });

    setSmallChartSeries(smallChartSeriesObjectArray);
    setModalSeries(modalSeriesObjectArray);
    setVisible(true);
  }, [allData, darkMode]);

  return (
    <>
      {visible ? (
        <div>
          {smallDisplay ? (
            <div>
              <div
                className="flex justify-end cursor-pointer"
                onClick={showModal}
              >
                <ExpandAltOutlined
                  style={{
                    fontSize: "18px",
                    color: darkMode ? "#6e8192" : "#3B3B3B",
                  }}
                />
              </div>

              <div className="chart">
                <ReactApexChart
                  options={options}
                  series={smallChartSeries}
                  type="bar"
                  height={250}
                  width={isMobile ? 220 : 450}
                />
              </div>
            </div>
          ) : (
            <div className="flex w-[27rem] h-[12rem] justify-center items-center">
              Loading...
            </div>
          )}
          <CustomModal
            visible={isModalOpen}
            onCancel={handleCancel}
            display={dispaly}
            setDisplay={setDisplay}
            isModalOpen={isModalOpen}
          >
            <div className="flex flex-col">
              <div className="chart">
                <ReactApexChart
                  options={modalOptions}
                  series={modalSeries}
                  type="bar"
                  height={530}
                  width={isMobile ? 300 : 1250}
                />
              </div>
              <div className="flex flex-col">
                <Cagr
                  cagr={cagr[0]}
                  bg="#F8FAD8"
                  text="#E89A06"
                  className="flex flex-row p-1 px-2 rounded-md"
                />
                <Cagr
                  cagr={cagr[1]}
                  bg="#D6F4CF"
                  text="#40bd25"
                  className="flex flex-row p-1 px-2 rounded-md"
                />
              </div>
            </div>
          </CustomModal>
        </div>
      ) : (
        <div className="flex w-[27rem] h-[12rem] justify-center items-center">
          Loading...
        </div>
      )}
    </>
  );
}

export default Graph4;
