import React, { useContext, useEffect, useState } from 'react';
import './ProfileView.css'; // Import your CSS file here
import userAvatar from  '../../assests/user.png'
import { useLogOut } from '../../Hooks/auth.hook';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../Context/ThemeContext';

function ProfileView() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState(userAvatar)
  const { darkMode } = useContext(ThemeContext);

  const navigate = useNavigate()
  const {
    mutate: logOutMutate,
    status: logOutStatus,
    data: logOutData,
  } = useLogOut();

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  useEffect(() => {
    if (logOutStatus == "success" && logOutData.status == 200) {
      navigate("/");
    }
  }, [logOutStatus]);

  return (
    <div className="profile-view" onClick={toggleMenu}>
      <div className="profile-picture">
        <img src={imageUrl} alt="Profile Picture"  style={{background : darkMode && "white"}}/>
      </div>
      {/* <span className="profile-name">John Doe</span> */}

      {menuVisible && (
        <div className="profile-menu">
          <ul>
            <li>Settings</li>
            <li onClick={()=> logOutMutate({})}>Logout</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default ProfileView;
