import React from "react";

const Cagr = ({ cagr, bg, text, className }) => {
  console.log(cagr);
  return (
    <div className="flex font-bold text-md justify-center my-2">
      <div className="flex flex-row space-x-5">
        <div className={className} style={{ background: bg, color: text }}>
          <p className="mr-2">{cagr[0].years}Y:</p>
          <p>{cagr[0].cagr}</p>
          <p className="font-bold">%</p>
        </div>
        <div className={className} style={{ background: bg, color: text }}>
          <p className="mr-2">{cagr[1].years}Y:</p>
          <p>{cagr[1].cagr}</p>
          <p className="font-bold">%</p>
        </div>
        <div className={className} style={{ background: bg, color: text }}>
          <p className="mr-2">{cagr[2].years}Y:</p>
          <p>{cagr[2].cagr}</p>
          <p className="font-bold">%</p>
        </div>
        <div className={className} style={{ background: bg, color: text }}>
          <p className="mr-2">{cagr[3].years}Y:</p>
          <p>{cagr[3].cagr}</p>
          <p className="font-bold">%</p>
        </div>
      </div>
    </div>
  );
};

export default Cagr;
