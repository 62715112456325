import React, { useState, useContext } from "react";
import SearchBar from "./components/SearchBarComponent";
import GraphsComponent from "./GraphsComponent";
import SideBar from "./components/SideBar";
import Header from "../../common/components/Header";
import "../../styles/theme.css";
import { ThemeContext } from "../../Context/ThemeContext";

function GraphPage() {
  const [sidebarState, setSidebarState] = useState(false);
  const { darkMode } = useContext(ThemeContext);

  // const SideBarHandler = () => {
  //   setSidebarState(true);
  //   console.log(sidebarState);
  // };
  return (
    <div className={darkMode ? "darkMode" : "lightMode"}>
      {/* <Header /> */}

      <div className="flex flex-row justify-between items-center  w-full">
        {/* Left side component */}
        <div className=" w-full min-h-screen px-0 relative z-10">
          <GraphsComponent />
        </div>

        {/* Right sidebar */}
        {/* 
        <div className="z-20 fixed top-0 right-0 h-screen flex justify-between md:mt-36 ">
          <SideBar
            sidebarState={sidebarState}
            setSidebarState={setSidebarState}
          />
        </div> */}
      </div>
    </div>
  );
}

export default GraphPage;
