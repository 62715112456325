export const USER_LOGIN = "auth/local/signin";
export const USER_REGISTER = "auth/local/signup";
export const GET_USER = "auth/verify-access-token";

export const FORGET_PASSWORD = "auth/send-otp";
export const VERIFY_OTP = "auth/verify-otp";
export const UPDATE_PASSWORD = "auth/update-password"

export const USER_LOGOUT = "auth/logout";
export const GET_API_ONE = (period, symbol, dailyData) =>
  `/charts/chart-one/${symbol}/${period}/${dailyData}`;
export const GET_API_TWO = (period, symbol) =>
  `/charts/chart-two/${symbol}/${period}`;
export const GET_API_THREE = (period, symbol) =>
  `/charts/chart-three/${symbol}/${period}`;
export const GET_API_FOUR = (period, symbol) =>
  `/charts/chart-four/${symbol}/${period}`;
export const GET_API_FIVE = (period, symbol) =>
  `/charts/chart-five/${symbol}/${period}`;
export const GET_API_SIX = (period, symbol) =>
  `/charts/chart-six/${symbol}/${period}`;
export const GET_API_TWO_AND_FOUR = (period, symbol) =>
  `/charts/combined-2-4/${symbol}/${period}`;
export const GET_API_TWO_AND_SEVEN = (period, symbol) =>
  `/charts/combined-2-7/${symbol}/${period}`;
export const GET_API_TWO_AND_SIX = (period, symbol) =>
  `/charts/combined-2-6/${symbol}/${period}`;
export const GET_API_TWO_THREE_AND_SIX = (period, symbol) =>
  `/charts/combined-2-3-6/${symbol}/${period}`;
export const GET_API_EPS_DILUTED = (period, symbol) =>
  `/charts/eps-diluted-graph/${symbol}/${period}`;
export const GET_API_ONE_FOUR_AND_SIX = (period, symbol) =>
  `/charts/combined-1-4-6/${symbol}/${period}`;
export const GET_COMPANIES_ON_SEARCH = (searchTerm, exchange) =>
  `/company/search?searchTerm=${searchTerm}&exchange=${exchange}`;
export const GET_COMPANY_DATA_ON_SELECT = (selectedSymbol) =>
  `/company/get-company-data?searchTerm=${selectedSymbol}`;
