import React from "react";
import OTPInput from "otp-input-react";
import { useState } from "react";
import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AnimationLottie from "../../lottieHelper/AnimationLottie";
import loginLottie from "../../../assests/login.json";
import ResetPassword from "../ResetPassword/ResetPassword";
import { useVerifyOtp , useForgetPassword } from "../../../Hooks/auth.hook";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";



const OtpPage = ({OTPlength, onsubmitOtp})=>{
    const location = useLocation()
    const navigate = useNavigate()
    const email = location.state
    const [showUpdatePass, setShowUpdatePass] = useState()
  const { mutate, status, isError, data } = useVerifyOtp();
  const { mutate:ResendOtp, status:ResendStatus, isError:ResendOtpError, data:ResendOtpData } = useForgetPassword();

  console.log("mutate",mutate, "status",status, isError, data,email);

    console.log("otp",OTPlength);

    const OTPLength = OTPlength || 4
   
    const [pinCode, setPinCode] = useState('');


    const isPinCodeComplete = pinCode.length === OTPLength;

    const onSubmit = (pinCode)=>{
        const values = {email:email, otp:pinCode}
        
        console.log("submit",pinCode);
        setShowUpdatePass(true)
        mutate(values)

        // navigate('/resetpassword')
        
    }

    useEffect(()=>{
        if(!email)
        navigate('/')
    },[])
    useEffect(() => {
        if (status == "success") {
          console.log(data.status);
          if (data.data.status == 200) {
            // toast.success(data?.data?.message, {
            //   autoClose: 1000,
            //   onClose:()=>
            // });
            navigate('/resetpassword', {state:email})
    
    
            console.log("sucessfull login data: ", data);
          } else if (data.data.status == 403) {
            toast.error(data.data.message, {
              autoClose: 1500,
            });
            // setShowLoading(false)
          }
          else if (data.status == 403) {
            toast.error("something went wrong", {
              autoClose: 1500,
            });
            // setShowLoading(false)
          }
        }
      }, [data, status]);

      useEffect(() => {
        if (ResendStatus == "success") {
          console.log(ResendOtpData.status);
          if (ResendOtpData.data.status == 200) {
            toast.success(ResendOtpData.data.message, {
              autoClose: 1000, 
            });
            // navigate('/resetpassword', {state:email})
    
    
            // console.log("sucessfull login data: ", data);
          } else if (ResendOtpData.data.status == 403) {
            toast.error(ResendOtpData.data.message, {
              autoClose: 1500,
            });
            // setShowLoading(false)
          }
          else if (ResendOtpData.status == 403) {
            toast.error("something went wrong", {
              autoClose: 1500,
            });
            // setShowLoading(false)
          }
        }
      }, [ResendOtpData, ResendStatus]);

    return (
    // showUpdatePass ? <ResetPassword /> :
     <div className="h-screen flex flex-row justify-center ">
       <ToastContainer/>
        <form
            onSubmit={(event) => {
                console.log("herere");
                event.preventDefault();
                onsubmitOtp ? onsubmitOtp() : onSubmit(pinCode);
            }}
            className="grid"
        >
            <div className="bg-white py-9 px-10  w-full flex flex-col justify-center items-center">
            <span className="mb-5">we have sent you 4 digits OTP on your email {email}</span>
            <h1 className="mb-5 font-medium text-2xl">Enter OTP</h1>
                <div className={`flex flex-row justify-center items-center gap-20 md:pl-12 md:pr-12 mt-4`}>
                    <OTPInput
                        value={pinCode}
                        onChange={setPinCode}
                        autoFocus
                        OTPLength={OTPLength}
                        otpType="number"
                        disabled={false}
                        className={"gap-1 ml-5"}
                        inputClassName="h-20 w-full p-1 rounded-md border-2 border-black  font-poppins font-semibold text-2xl text-center"
                        

                    />
                </div>
                <div className="flex justify-center items-center w-full">
                    <button
                        // withMaintenanceMode={withMaintenanceMode}
                        type="submit"
                        disabled={!isPinCodeComplete}
                        className={`flex justify-center items-center w-[50%] h-[40%] font-medium text-base rounded-md font-poppins pt-5 pb-5 mt-10  ${isPinCodeComplete ? `bg-indigo-600 text-white cursor-pointer` : `bg-[#d0cdcd] text-white cursor-not-allowed`
                            } `}
                    >
                        Submit
                    </button>

                </div>
                <p className={"font-poppins text-dark font-normal text-sm leading-6 text-center mb-5 mt-15"}>
                    {/* {formatMessage({ id: 'pinCode.didNotReceieved' })} */}
                    didn't received ?
                </p>
                <div className="flex justify-center">
                <p className={"text-xs leading-4 text-primary text-center cursor-pointer w-fit"} 
                onClick={() =>  ResendOtp({email})}
                >
                    Resend OTP
                    {/* {formatMessage({ id: 'pinCode.resendIt' })} */}
                </p>
                </div>
            </div>
           
        </form >
        <div className="w-[50%] hidden  md:flex justify-center items-center bg-gradient-to-r from-[#C0DBEA] via-30% to-[#FCC8D1] to-90% ">
              <AnimationLottie animationPath={loginLottie} />
            </div>
        </div>
    );
}
export default OtpPage