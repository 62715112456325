import DarkModeToggle from "react-dark-mode-toggle";
import React, { useContext } from "react";
import { ThemeContext } from "../../../Context/ThemeContext";

const DarkModeToggleComponent = () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);

  return (
    <div className="w-[50px] h-[25px] border-white border-2 rounded-3xl justify-center align-middle mt-2 ">
      <DarkModeToggle onChange={toggleDarkMode} checked={darkMode} size={45} />
    </div>
  );
};

export default DarkModeToggleComponent;
