import React, { useState, useEffect, useContext } from "react";

import { useMediaQuery } from "react-responsive";
import ReactApexChart from "react-apexcharts";
import "../style/style.css";
import { ExpandAltOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { Button, Modal } from "antd";
import CustomModal from "../components/CustomModal";
import Tabs from "../components/Tabs";
import getSymbolFromCurrency from "currency-symbol-map";
import { ThemeContext } from "../../../Context/ThemeContext";

dayjs.extend(quarterOfYear);

function StackedGraph({
  graphData,
  fd,
  qd,
  years,
  optionsArray,
  period,
  currencyText,
}) {
  const { darkMode } = useContext(ThemeContext);

  const [smallChartData, setSmallChartData] = useState(
    period == "annual" ? fd : qd
  );
  const [allData, setAllData] = useState(graphData);
  const [currency, setCurrency] = useState(getSymbolFromCurrency(currencyText));

  const [visible, setVisible] = useState(null);
  const [options, setOptions] = useState({});
  const [modalOptions, setModalOptions] = useState({});
  const [dispaly, setDisplay] = useState(false);
  const [smallDisplay, setSmallDisplay] = useState(false);

  const [smallChartSeries, setSmallChartSeries] = useState([]);
  const [modalSeries, setModalSeries] = useState([]);

  const [activePeriodTab, setActivePeriodTab] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDisplay(false);
  };

  useEffect(() => {
    setSmallDisplay(false);

    // console.log(allData);
    smallChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
    allData.sort((a, b) => new Date(a.date) - new Date(b.date));

    // let denominator;
    // if (unit == " th") {
    //   denominator = 1000;
    // } else if (unit == " m") {
    //   denominator = 1000000;
    // } else if (unit == " B") {
    //   denominator = 1000000000;
    // }

    setTimeout(() => setSmallDisplay(true), 10);

    const newOptionsObject = {
      sellingGeneralAndAdministrativeExpenses: "SG&A",
      researchAndDevelopmentExpenses: "R&D",
      capitalExpenditure: "CAPEX",
      sellingAndMarketingExpenses: "S&M",
    };

    const newOptionsEmptyObject = {
      sellingGeneralAndAdministrativeExpenses: "S̶G̶&̶A̶",
      researchAndDevelopmentExpenses: "R̶&̶D̶",
      capitalExpenditure: "C̶A̶P̶E̶X̶",
      sellingAndMarketingExpenses: "S̶&̶M̶",
    };

    //for series

    const smallChartSeriesObjects = optionsArray.map((option) => {
      if (option !== "date") {
        let a;
        if (option == "capitalExpenditure") {
          a = smallChartData.map((item) => {
            const value = item[option] ? item[option] : 0;
            const positiveValue = value < 0 ? value * -1 : value;
            return {
              // x: item.date,
              x: `${item.date.substr(5, 2)}/${item.date.substr(
                8,
                2
              )}/${item.date.substr(0, 4)}`,
              // y: positiveValue / denominator,
              y: positiveValue == 0 ? null : positiveValue,
            };
          });
          const filtered = a.filter((item) => {
            return item.y !== null;
          });
          if (filtered.length > 0) {
            return {
              name: newOptionsObject[option],
              data: a,
            };
          } else {
            return {
              name: newOptionsEmptyObject[option],
              data: a,
            };
          }
        } else {
          a = smallChartData.map((item) => {
            return {
              x: `${item.date.substr(5, 2)}/${item.date.substr(
                8,
                2
              )}/${item.date.substr(0, 4)}`,
              y: item[option] && item[option] !== 0 ? item[option] : null,
            };
          });
        }
        const filtered = a.filter((item) => {
          return item.y !== null;
        });

        if (filtered.length > 0) {
          return {
            name: newOptionsObject[option],
            data: a,
          };
        } else {
          return {
            name: newOptionsEmptyObject[option],
            data: a,
          };
        }
      }
    });

    const smallChartSeriesObjectArray = smallChartSeriesObjects.filter(
      (item) => {
        return item !== undefined;
      }
    );

    // console.log("smallChartSeriesObjectArray", smallChartSeriesObjectArray);

    // for modal series

    const modalSeriesObjects = optionsArray.map((option) => {
      if (option !== "date") {
        let a;
        if (option == "capitalExpenditure") {
          a = allData.map((item) => {
            const value = item[option] ? item[option] : 0;
            const positiveValue = value < 0 ? value * -1 : value;
            return {
              // x: item.date,
              x: `${item.date.substr(5, 2)}/${item.date.substr(
                8,
                2
              )}/${item.date.substr(0, 4)}`,
              // y: positiveValue / denominator,
              y: positiveValue,
            };
          });
        } else {
          a = allData.map((item) => {
            return {
              // x: item.date,
              x: `${item.date.substr(5, 2)}/${item.date.substr(
                8,
                2
              )}/${item.date.substr(0, 4)}`,
              // y: item[option] ? item[option] / denominator : 0,
              y: item[option] ? item[option] : 0,
            };
          });
        }

        if (a.length > 0) {
          return {
            name: newOptionsObject[option],
            data: a,
          };
        } else {
          return {
            name: newOptionsEmptyObject[option],
            data: a,
          };
        }
      }
    });

    const modalSeriesObjectArray = modalSeriesObjects.filter((item) => {
      return item !== undefined;
    });

    // console.log("modalSeriesObjectArray", modalSeriesObjectArray);

    //for x-axis or dates

    const smallChartDates = smallChartData.map((item) => {
      return `${item.date.substr(5, 2)}/${item.date.substr(
        8,
        2
      )}/${item.date.substr(0, 4)}`;
    });

    const allDates = allData.map((item) => {
      return `${item.date.substr(5, 2)}/${item.date.substr(
        8,
        2
      )}/${item.date.substr(0, 4)}`;
    });

    setOptions({
      chart: {
        // type: "bar",
        // height: 350,
        stacked: true,
        toolbar: {
          show: false,
          // offsetX: -20,
          // offsetY: -20,
          // tools: {
          //   download: false,
          //   selection: false,
          //   zoom: true,
          //   zoomin: true,
          //   zoomout: true,
          //   pan: false,
          //   reset: true | '<img src="/static/icons/reset.png" width="20">',
          // },
        },
      },
      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      tooltip: {
        enabled: true,
        theme: darkMode ? "dark" : "light",
        y: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return currency + v.toPrecision(4) + unit;
          },
        },
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       legend: {
      //         position: "top",
      //         offsetX: 0,
      //         offsetY: 0,
      //       },
      //     },
      //   },
      // ],
      plotOptions: {
        bar: {
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "50%"
                : "60%"
              : years == "10"
              ? period == "annual"
                ? "70%"
                : "60%"
              : period == "annual"
              ? "70%"
              : "60%",

          dataLabels: {
            total: {
              enabled: false,
            },
          },
        },
      },
      stroke: {
        width:
          years == "5"
            ? period == "annual"
              ? 4
              : 6
            : years == "10"
            ? period == "annual"
              ? 4
              : 5
            : period == "annual"
            ? 3
            : 3,
        colors: ["#2E8BC0", "#feb019", "#499894", "#ff6962"],
      },
      xaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          style: {
            fontSize: "12px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
          rotate: -45,
          rotateAlways: true,
        },
        categories: smallChartDates,
        tickPlacement: "on",
        tickAmount: 24,
      },
      yaxis: {
        forceNiceScale: true,
        tickAmount: 6,
        labels: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return currency + v.toString().substring(0, 4) + unit;
          },
          style: {
            fontSize: "14px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          colors: darkMode ? "#fff" : "#000",
        },
        showForSingleSeries: true,
        markers: {
          width: 30,
          height: 12,
          fillColors: ["#2E8BC0", "#feb019", "#499894", "#ff6962"],
        },
      },
      fill: {
        opacity: 1,
        colors: ["#2E8BC0", "#feb019", "#499894", "#ff6962"],
      },
    });
    setModalOptions({
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
          // offsetX: -20,
          // offsetY: 0,
          // tools: {
          //   download: false,
          //   selection: false,
          //   zoom: true,
          //   zoomin: true,
          //   zoomout: true,
          //   pan: false,
          //   reset: true | '<img src="/static/icons/reset.png" width="20">',
          // },
        },
      },
      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      tooltip: {
        enabled: true,
        theme: darkMode ? "dark" : "light",
        y: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return currency + v.toPrecision(4) + unit;
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "40%"
                : "60%"
              : years == "10"
              ? period == "annual"
                ? "40%"
                : "70%"
              : period == "annual"
              ? "70%"
              : "60%",
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              // formatter: function (value) {
              //   const v = parseFloat(value);
              //   return currency + v.toPrecision(2);
              // },
              // style: {
              //   fontSize: "8px",
              //   fontWeight: 500,
              // },
            },
          },
        },
      },
      stroke: {
        width:
          years == "5"
            ? period == "annual"
              ? 4
              : 6
            : years == "10"
            ? period == "annual"
              ? 4
              : 5
            : period == "annual"
            ? 3
            : 3,
        colors: ["#2E8BC0", "#feb019", "#499894", "#ff6962"],
      },
      xaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          style: {
            fontSize: "12px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
          rotate: -45,
          rotateAlways: true,
        },
        categories: allDates,
        tickAmount: period == "annual" ? 45 : 60,
      },
      yaxis: {
        tickAmount: 8,
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return currency + v.toString().substring(0, 4) + unit;
          },
          style: {
            fontSize: "14px",
            paddingLeft: "30rem",
            background: "red",
            colors: darkMode ? "#fff" : "#000",
          },
        },
      },

      legend: {
        position: "top",
        labels: {
          colors: darkMode ? "#fff" : "#000",
        },
        offsetY: 0,
        showForSingleSeries: true,
        markers: {
          width: 35,
          height: 12,
          fillColors: ["#2E8BC0", "#feb019", "#499894", "#ff6962"],
        },
      },

      fill: {
        opacity: 1,
        colors: ["#2E8BC0", "#feb019", "#499894", "#ff6962"],
      },
    });
    setSmallChartSeries(smallChartSeriesObjectArray);
    setModalSeries(modalSeriesObjectArray);
    setVisible(true);
  }, [allData, darkMode]);
  return (
    <>
      {visible ? (
        <div>
          {smallDisplay ? (
            <div>
              <div
                className="flex justify-end cursor-pointer"
                onClick={showModal}
              >
                {/* <div className="border-2 border-black justify-center w-6 h-6"> */}
                <ExpandAltOutlined
                  style={{
                    fontSize: "18px",
                    color: darkMode ? "#6e8192" : "#3B3B3B",
                  }}
                />
                {/* </div> */}
              </div>

              <div className="chart small">
                <ReactApexChart
                  options={options}
                  series={smallChartSeries}
                  type="bar"
                  height={250}
                  width={isMobile ? 220 : 450}
                />
              </div>
            </div>
          ) : (
            <div className="flex w-[27rem] h-[12rem] justify-center items-center">
              Loading...
            </div>
          )}
          <CustomModal
            visible={isModalOpen}
            onCancel={handleCancel}
            display={dispaly}
            setDisplay={setDisplay}
            isModalOpen={isModalOpen}
          >
            <div className="chart modal" style={{ paddingLeft: "0rem" }}>
              <ReactApexChart
                options={modalOptions}
                series={modalSeries}
                type="bar"
                height={530}
                width={isMobile ? 300 : 1250}
                // style={{ paddingLeft: "1rem" }}
              />
            </div>
          </CustomModal>
        </div>
      ) : (
        <div className="flex w-[27rem] h-[12rem] justify-center items-center">
          Loading...
        </div>
      )}
    </>
  );
}

export default StackedGraph;
