import { useQuery, useMutation } from "react-query";
import {
  getApiOne,
  getApiTwo,
  getApiThree,
  getApiFour,
  getApiFive,
  getApiSix,
  getApiTwoAndFour,
  getApiTwoAndSeven,
  getApiTwoAndSix,
  getCompaniesOnSearch,
  getCompanyDataOnSelect,
  getApiEpsDiluted,
  getApiOneFourAndSix,
  getApiTwoThreeAndSix,
} from "../store/services/service";

export const useGetApiOne = (period, symbol, dailyData) => {
  return useQuery("getApiOne", () => {
    return getApiOne(period, symbol, dailyData);
  });
};

export const useGetApiTwo = (period, symbol) => {
  return useQuery("getApiTwo", () => {
    return getApiTwo(period, symbol);
  });
};

export const useGetApiThree = (period, symbol) => {
  return useQuery("getApiThree", () => {
    return getApiThree(period, symbol);
  });
};

export const useGetApiFour = (period, symbol) => {
  return useQuery("getApiFour", () => {
    return getApiFour(period, symbol);
  });
};

export const useGetApiFive = (period, symbol) => {
  return useQuery("getApiFive", () => {
    return getApiFive(period, symbol);
  });
};

export const useGetApiSix = (period, symbol) => {
  return useQuery("getApiSix", () => {
    return getApiSix(period, symbol);
  });
};

export const useGetApiTwoAndFour = (period, symbol) => {
  return useQuery("getApiTwoAndFour", () => {
    return getApiTwoAndFour(period, symbol);
  });
};

export const useGetApiEpsDiluted = (period, symbol) => {
  return useQuery("getApiEpsDiluted", () => {
    return getApiEpsDiluted(period, symbol);
  });
};

export const useGetApiTwoAndSeven = (period, symbol) => {
  return useQuery("getApiTwoAndSeven", () => {
    return getApiTwoAndSeven(period, symbol);
  });
};

export const useGetApiTwoAndSix = (period, symbol) => {
  return useQuery("getApiTwoAndSix", () => {
    return getApiTwoAndSix(period, symbol);
  });
};

export const useGetApiOneFourAndSix = (period, symbol) => {
  return useQuery("getApiOneFourAndSix", () => {
    return getApiOneFourAndSix(period, symbol);
  });
};

export const useGetApiTwoThreeAndSix = (period, symbol) => {
  return useQuery("getApiTwoThreeAndSix", () => {
    return getApiTwoThreeAndSix(period, symbol);
  });
};

export const useGetCompaniesOnSearch = (searchTerm, exchange) => {
  return useQuery("getCompaniesOnSearch", () => {
    return getCompaniesOnSearch(searchTerm, exchange);
  });
};

export const useGetCompanyDataOnSelect = (selectedSymbol) => {
  return useQuery("getCompanyDataOnSelect", () => {
    return getCompanyDataOnSelect(selectedSymbol);
  });
};

// export const useAddQuestion = () => {
//   return useMutation(addQuestion);
// };

// export const useGetQuestions = (id) => {
// return useQuery("getQuestions", () => {
//   return getQuestions(id);
// });
// };
