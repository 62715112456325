import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import "../style/style.css";
import { ExpandAltOutlined } from "@ant-design/icons";
import "../style/style.css";
import { Button, Modal } from "antd";

import CustomModal from "../components/CustomModal";
import Tabs from "../components/Tabs";
import { colors } from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import { ThemeContext } from "../../../Context/ThemeContext";
import Cagr from "../components/Cagr";

dayjs.extend(quarterOfYear);

function BasicColumnGraph({
  graphData,
  fd,
  qd,
  period,
  years,
  // unit,
  optionsArray,
  chartNum,
  currencyText,
  cagr,
}) {
  const { darkMode } = useContext(ThemeContext);

  const [smallChartData, setSmallChartData] = useState(
    period == "annual" ? fd : qd
  );
  const [allData, setAllData] = useState(graphData);
  const [currency, setCurrency] = useState(getSymbolFromCurrency(currencyText));

  const [visible, setVisible] = useState(null);
  const [options, setOptions] = useState({});
  const [modalOptions, setModalOptions] = useState({});

  const [smallChartSeries, setSmallChartSeries] = useState([]);
  const [modalSeries, setModalSeries] = useState([]);

  const [activePeriodTab, setActivePeriodTab] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dispaly, setDisplay] = useState(false);
  const [smallDisplay, setSmallDisplay] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDisplay(false);
  };

  let color;
  let markerColor;
  let bgColor;
  let textColor;

  if (chartNum == "2") {
    color = ["#fabc5e"];
    markerColor = ["#fabc5e"];
    bgColor = "#F8FAD8";
    textColor = "#F7AA18";
  } else if (chartNum == "7") {
    color = ["#ffbe7d"];
    markerColor = ["#ffbe7d"];
    bgColor = "#FBDDBE";
    textColor = "#F89A39";
  } else if (chartNum == "12") {
    color = ["#2E8BC0"];
    markerColor = ["#2E8BC0"];
    bgColor = "#9ED8F8";
    textColor = "#2E8BC0";
  } else if (chartNum == "6") {
    color = ["#499894"];
    markerColor = ["#499894"];
    bgColor = "#D1FBFA";
    textColor = "#499894";
  } else if (chartNum == "10") {
    color = ["#ff6962"];
    markerColor = ["#ff6962"];
  }

  useEffect(() => {
    setSmallDisplay(false);
    // console.log(annualData);
    smallChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
    allData.sort((a, b) => new Date(a.date) - new Date(b.date));

    // let denominator;
    // if (unit == " th") {
    //   denominator = 1000;
    // } else if (unit == " m") {
    //   denominator = 1000000;
    // } else if (unit == " B") {
    //   denominator = 1000000000;
    // }

    setTimeout(() => setSmallDisplay(true), 10);

    const newOptionsObject = {
      revenue: "Revenue",
      netIncome: "Net Income",
      weightedAverageShsOutDil: "Outstanding Shares",
      adjDividend: "Dividend",
      ebitda: "EBITDA",
      interestCoverage: "Interest Coverage",
    };
    const newOptionsEmptyObject = {
      revenue: "R̶e̶v̶e̶n̶u̶e̶",
      netIncome: " ̶N̶e̶t̶ ̶I̶n̶c̶o̶m̶e̶",
      weightedAverageShsOutDil: "O̶u̶t̶s̶t̶a̶n̶d̶i̶n̶g̶ S̶h̶a̶r̶e̶s̶",
      adjDividend: " ̶D̶i̶v̶i̶d̶e̶n̶d̶ ",
      ebitda: "E̶B̶I̶T̶D̶A̶",
      interestCoverage: " ̶I̶n̶t̶e̶r̶e̶s̶t̶ ̶C̶o̶v̶e̶r̶a̶g̶e̶",
    };

    //for series

    const smallChartSeriesObjects = optionsArray.map((option) => {
      if (option !== "date") {
        let a;

        if (option == "revenue") {
          a = smallChartData.map((item) => {
            return {
              x: `${item.date.substr(0, 4)}/${item.date.substr(
                5,
                2
              )}/${item.date.substr(8, 2)}`,
              y: item[option] ? item[option] : 0,
              goals: [
                {
                  name: "Estimated",
                  value: item["revenueEstimated"]
                    ? item["revenueEstimated"]
                    : 0,
                  strokeHeight: 2,
                  strokeColor: "#775DD0",
                },
              ],
            };
          });
        } else {
          a = smallChartData.map((item) => {
            return {
              x: `${item.date.substr(0, 4)}/${item.date.substr(
                5,
                2
              )}/${item.date.substr(8, 2)}`,
              y: item[option] ? item[option] : 0,
            };
          });
        }
        const filtered = a.filter((item) => {
          return item.y !== null;
        });
        if (filtered.length > 0) {
          return {
            name: newOptionsObject[option],
            data: a,
          };
        } else {
          return {
            name: newOptionsEmptyObject[option],
            data: [],
          };
        }
      }
    });

    const smallChartSeriesObjectArray = smallChartSeriesObjects.filter(
      (item) => {
        return item !== undefined;
      }
    );

    // console.log("smallChartSeriesObjectArray", smallChartSeriesObjectArray);

    //for modal series

    const modalSeriesObject = optionsArray.map((option) => {
      if (option !== "date") {
        let a;
        if (option == "revenue") {
          a = allData.map((item) => {
            return {
              x: `${item.date.substr(0, 4)}/${item.date.substr(
                5,
                2
              )}/${item.date.substr(8, 2)}`,
              y: item[option] ? item[option] : 0,
              goals: [
                {
                  name: "Estimated",
                  value: item["revenueEstimated"]
                    ? item["revenueEstimated"]
                    : 0,
                  strokeHeight: 2,
                  strokeColor: "#775DD0",
                },
              ],
            };
          });
        } else {
          a = allData.map((item) => {
            return {
              x: `${item.date.substr(0, 4)}/${item.date.substr(
                5,
                2
              )}/${item.date.substr(8, 2)}`,
              y: item[option] ? item[option] : 0,
            };
          });
        }
        const filtered = a.filter((item) => {
          return item.y !== null;
        });
        if (filtered.length > 0) {
          return {
            name: newOptionsObject[option],
            data: a,
          };
        } else {
          return {
            name: newOptionsEmptyObject[option],
            data: [],
          };
        }
      }
    });

    const modalSeriesObjectArray = modalSeriesObject.filter((item) => {
      return item !== undefined;
    });

    // console.log("ModalSeriesObjectArray", modalSeriesObjectArray);

    //for x-axis or dates

    const smallChartDates = smallChartData.map((item) => {
      return `${item.date.substr(0, 4)}`;
    });

    const allDates = allData.map((item) => {
      return `${item.date.substr(0, 4)}/${item.date.substr(
        5,
        2
      )}/${item.date.substr(8, 2)}`;
    });

    setOptions({
      tooltip: {
        marker: {
          // show: true,
          show: false,
          fillColors: markerColor,
        },
        theme: darkMode ? "dark" : "light",
        y: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return chartNum == "12"
              ? v.toFixed(2) + " " + unit
              : chartNum == "10"
              ? v.toFixed(2)
              : currency + " " + v.toFixed(2) + " " + unit;
          },
        },
      },
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "50%"
                : "70%"
              : years == "10"
              ? period == "annual"
                ? "70%"
                : "70%"
              : period == "annual"
              ? "70%"
              : "70%",
          barHeight: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // width:
        //   years == "5"
        //     ? period == "annual"
        //       ? 4
        //       : 4
        //     : years == "10"
        //     ? period == "annual"
        //       ? 4
        //       : 4
        //     : period == "annual"
        //     ? 4
        //     : 4,
        colors: color,
      },

      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      xaxis: {
        forceNiceScale: true,
        type: "category",
        labels: {
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          rotate: -45,
          // rotateAlways: years == "5" ? false : true,
          rotateAlways: true,
          showDuplicates: false,
          style: {
            fontSize: "12px",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        // categories: period == "annual" ? annualDates : quarterDates,
        categories: smallChartDates,
        tickPlacement: "on",
        tickAmount: 24,
      },
      yaxis: {
        forceNiceScale: true,
        // max:
        labels: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }

            return chartNum == "12"
              ? v.toString().substring(0, 4) + unit
              : chartNum == "10"
              ? v.toString().substring(0, 4)
              : currency + v.toString().substring(0, 4) + unit;
          },
          style: {
            fontSize: "14px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        tickAmount: 7,
      },
      legend: {
        position: "top",
        showForSingleSeries: true,
        markers: {
          fillColors: markerColor,
          width: 30,
          height: 12,
        },
        labels: {
          colors: darkMode ? "#fff" : "#000",
        },
      },

      fill: {
        opacity: 1,
        colors: color,
      },
    });
    // console.log(allDatesYears);
    setModalOptions({
      tooltip: {
        marker: {
          // show: true,
          show: false,
          fillColors: markerColor,
        },
        theme: darkMode ? "dark" : "light",
        y: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return chartNum == "12"
              ? v.toFixed(2) + " " + unit
              : chartNum == "10"
              ? v.toFixed(2)
              : currency + " " + v.toFixed(2) + " " + unit;
          },
        },
      },
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "30%"
                : "60%"
              : years == "10"
              ? period == "annual"
                ? "50%"
                : "70%"
              : period == "annual"
              ? "70%"
              : "70%",
          endingShape: "rounded",
          barHeight: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        // width:
        //   years == "5"
        //     ? period == "annual"
        //       ? 4
        //       : 4
        //     : years == "10"
        //     ? period == "annual"
        //       ? 4
        //       : 4
        //     : period == "annual"
        //     ? 4
        //     : 4,
        colors: color,
      },
      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      xaxis: {
        forceNiceScale: true,
        type: "category",
        labels: {
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          rotate: -45,
          rotateAlways: true,
          style: {
            fontSize: "14px",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        categories: allDates,
        tickPlacement: "on",
        tickAmount: period == "annual" ? 40 : 60,
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";

            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return chartNum == "12"
              ? v.toString().substring(0, 4) + unit
              : chartNum == "10"
              ? v.toString().substring(0, 4)
              : currency + v.toString().substring(0, 4) + unit;

            //
          },
          style: {
            fontSize: "14px",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        tickAmount: 8,
      },
      legend: {
        position: "top",
        showForSingleSeries: true,
        markers: {
          fillColors: markerColor,
          width: 35,
          height: 12,
        },
        labels: {
          colors: darkMode ? "#fff" : "#000",
        },
      },
      fill: {
        opacity: 1,
        colors: color,
      },
    });
    // setAnnualSeries(annualSeriesObjectArray);
    // setQuarterSeries(quarterSeriesObjectArray);
    setSmallChartSeries(smallChartSeriesObjectArray);

    setModalSeries(modalSeriesObjectArray);
    setVisible(true);
  }, [allData, darkMode]);
  return (
    <>
      {visible ? (
        <div>
          {smallDisplay ? (
            <div>
              <div
                className="flex justify-end cursor-pointer"
                onClick={showModal}
              >
                {/* <div className="border-2 border-black justify-center w-6 h-6"> */}
                <ExpandAltOutlined
                  style={{
                    fontSize: "18px",
                    color: darkMode ? "#6e8192" : "#3B3B3B",
                  }}
                />
                {/* </div> */}
              </div>

              <div className="chart">
                <ReactApexChart
                  options={options}
                  // series={period == "annual" ? annualSeries : quarterSeries}
                  series={smallChartSeries}
                  type="bar"
                  height={250}
                  width={isMobile ? 220 : 450}
                />
              </div>
            </div>
          ) : (
            <div className="flex w-[27rem] h-[12rem] justify-center items-center">
              Loading...
            </div>
          )}
          <CustomModal
            visible={isModalOpen}
            onCancel={handleCancel}
            display={dispaly}
            setDisplay={setDisplay}
            isModalOpen={isModalOpen}
          >
            <div className="flex flex-col">
              <div className="chart">
                <ReactApexChart
                  options={modalOptions}
                  series={modalSeries}
                  type="bar"
                  height={530}
                  width={isMobile ? 300 : 1250}
                />
              </div>
              {chartNum !== "10" && (
                <Cagr
                  cagr={cagr}
                  bg={bgColor}
                  text={textColor}
                  className="flex flex-row p-1 px-2 rounded-md"
                />
              )}
            </div>
          </CustomModal>
        </div>
      ) : (
        <div className="flex w-[27rem] h-[12rem] justify-center items-center">
          Loading...
        </div>
      )}
    </>
  );
}

export default BasicColumnGraph;
