import React, { useState, useEffect, useContext } from "react";
import { Spin } from "antd";
import { Popover, Steps } from "antd";
import Tabs from "./components/Tabs";
// import dateRange from "./data/dateRange";
// import Slider from "@mui/material/Slider";
// import { Box } from "@mui/material";
import BasicColumnGraph from "./graphs/BasicColumnGraph";
import Graph5 from "./graphs/Graph5";
import Graph11 from "./graphs/Graph11";
import Graph4 from "./graphs/Graph4";
import Graph3 from "./graphs/Graph3";
import StackedGraph from "./graphs/StackedGraph";
import AreaChart from "./graphs/AreaChart";
import SearchBar from "./components/SearchBarComponent";
import DarkModeToggleComponent from "./components/DarkModeToggleComponent";
// import { ThemeContext } from "../../Context/ThemeContext";

import {
  useGetApiOne,
  useGetApiTwo,
  // useGetApiThree,
  useGetApiFour,
  useGetApiFive,
  useGetApiSix,
  useGetApiTwoAndFour,
  useGetApiTwoAndSix,
  // useGetApiTwoAndSeven,
  useGetApiEpsDiluted,
  useGetApiOneFourAndSix,
  useGetApiTwoThreeAndSix,
} from "../../Hooks/charts.hook";
import Graph9 from "./graphs/Graph9";
import ProfileView from "../../common/components/ProfileView";

function GraphsComponent() {
  // const { darkMode } = useContext(ThemeContext);

  const [activePeriodTab, setActivePeriodTab] = useState("annual");
  const [activeYearsTab, setActiveYearsTab] = useState("5");
  const [companyInfo, setCompanyInfo] = useState(null);
  const [filterYearSmall, setFilterYearSmall] = useState(
    activeYearsTab == "3"
      ? "2021"
      : activeYearsTab == "5"
      ? activePeriodTab == "annual"
        ? "2018"
        : "2021"
      : activeYearsTab == "10"
      ? activePeriodTab == "annual"
        ? "2013"
        : "2021"
      : activePeriodTab == "annual"
      ? "2009"
      : "2021"
  );
  const [filterYearModal, setFilterYearModal] = useState(
    activeYearsTab == "3"
      ? "2021"
      : activeYearsTab == "5"
      ? "2018"
      : activeYearsTab == "10"
      ? "2013"
      : ""
  );

  // const [activeUnitTab, setActiveUnitTab] = useState(" B");
  const [warning, setWarning] = useState(false);
  const [symbol, setSymbol] = useState("");
  const [flag, setFlag] = useState(false);
  const [dailyData, setDailyData] = useState("false");
  const [price, setPrice] = useState();
  const [marketCap, setMarketCap] = useState();

  const {
    data: ApiOneData,
    remove: removeOne,
    status: ApiOneStatus,
  } = useGetApiOne(activePeriodTab, symbol, dailyData);
  const {
    data: ApiTwoData,
    remove: removetwo,
    status: ApiTwoStatus,
  } = useGetApiTwo(activePeriodTab, symbol);
  // const {
  //   data: ApiThreeData,
  //   remove: removeThree,
  //   status: ApiThreeStatus,
  // } = useGetApiThree(activePeriodTab, symbol);
  const {
    data: ApiFourData,
    remove: removeFour,
    status: ApiFourStatus,
  } = useGetApiFour(activePeriodTab, symbol);
  const {
    data: ApiFiveData,
    remove: removeFive,
    status: ApiFiveStatus,
  } = useGetApiFive(activePeriodTab, symbol);
  const {
    data: ApiSixData,
    remove: removeSix,
    status: ApiSixStatus,
  } = useGetApiSix(activePeriodTab, symbol);
  const {
    data: ApiTwoAndFourData,
    remove: removeTwoFour,
    status: ApiTwoAndFourStatus,
  } = useGetApiTwoAndFour(activePeriodTab, symbol);
  const {
    data: ApiTwoAndSixData,
    remove: removeTwoSix,
    status: ApiTwoAndSixStatus,
  } = useGetApiTwoAndSix(activePeriodTab, symbol);
  const {
    data: ApiEpsDilutedData,
    remove: removeEpsDiluted,
    status: ApiEpsDilutedStatus,
  } = useGetApiEpsDiluted(activePeriodTab, symbol);
  // const {
  //   data: ApiTwoAndSevenData,
  //   remove: removeTwoSeven,
  //   status: ApiTwoAndSevenStatus,
  // } = useGetApiTwoAndSeven(activePeriodTab, symbol);
  const {
    data: ApiOneFourAndSixData,
    remove: removeTwoFourSix,
    status: ApiOneFourAndSixStatus,
  } = useGetApiOneFourAndSix(activePeriodTab, symbol);
  const {
    data: ApiTwoThreeAndSixData,
    remove: removeTwoThreeSix,
    status: ApiTwoThreeAndSixStatus,
  } = useGetApiTwoThreeAndSix(activePeriodTab, symbol);

  // Modal All data
  const [apiOnePrice, setApiOnePrice] = useState(null);
  const [apiOneMC, setApiOneMC] = useState(null);
  const [apiTwo, setApiTwo] = useState(null);
  // const [apiThree, setApiThree] = useState(null);
  const [apiFour, setApiFour] = useState(null);
  const [apiFive, setApiFive] = useState(null);
  const [apiSix, setApiSix] = useState(null);
  const [apiTwoAndFour, setApiTwoAndFour] = useState(null);
  const [apiTwoAndSix, setApiTwoAndSix] = useState(null);
  // const [apiTwoAndSeven, setApiTwoAndSeven] = useState(null);
  const [apiEpsDiluted, setApiEpsDiluted] = useState(null);
  const [apiOneFourAndSix, setApiOneFourAndSix] = useState(null);
  const [apiTwoThreeAndSix, setApiTwoThreeAndSix] = useState(null);

  // annual filtered data
  const [filteredTwo, setFilteredTwo] = useState(null);
  // const [filteredThree, setFilteredThree] = useState(null);
  const [filteredFour, setFilteredFour] = useState(null);
  const [filteredFive, setFilteredFive] = useState(null);
  const [filteredSix, setFilteredSix] = useState(null);
  const [filteredTwoAndFour, setFilteredTwoAndFour] = useState(null);
  const [filteredTwoAndSix, setFilteredTwoAndSix] = useState(null);
  // const [filteredTwoAndSeven, setFilteredTwoAndSeven] = useState(null);
  const [filteredEpsDiluted, setFilteredEpsDiluted] = useState(null);
  const [filteredOneFourAndSix, setFilteredOneFourAndSix] = useState(null);
  const [filteredTwoThreeAndSix, setFilteredTwoThreeAndSix] = useState(null);

  //quarter filtered data
  const [two, setTwo] = useState(null);
  // const [three, setThree] = useState(null);
  const [four, setFour] = useState(null);
  const [five, setFive] = useState(null);
  const [six, setSix] = useState(null);
  const [twoAndFour, setTwoAndFour] = useState(null);
  const [twoAndSix, setTwoAndSix] = useState(null);
  // const [twoAndSeven, setTwoAndSeven] = useState(null);
  const [epsDiluted, setEpsDiluted] = useState(null);
  const [oneFourAndSix, setOneFourAndSix] = useState(null);
  const [twoThreeAndSix, setTwoThreeAndSix] = useState(null);

  // CAGR
  const [cagrPrice, setCagrPrice] = useState(null);
  const [cagrMC, setCagrMC] = useState(null);
  const [cagrRevenue, setCagrRevenue] = useState(null);
  const [cagrFCF_SBC, setCagrFCF_SBC] = useState(null);
  const [cagrEps, setCagrEps] = useState(null);
  const [cagrDividend, setCagrDividend] = useState(null);
  const [cagrNetIncome, setCagrNetIncome] = useState(null);
  const [cagrEbitda, setCagrEbitda] = useState(null);
  const [cagrWeightedAverage, setCagrWeightedAverage] = useState(null);

  useEffect(() => {
    if (flag) {
      removeOne();
      removetwo();
      // removeThree();
      removeFour();
      removeFive();
      removeSix();
      removeTwoFour();
      removeTwoSix();
      // removeTwoSeven();
      removeEpsDiluted();
      removeTwoFourSix();
      removeTwoThreeSix();

      // setApiOnePrice(null);
      // setApiOneMC(null);
      setApiTwo(null);
      // setApiThree(null);
      // setApiFour(null);
      // setApiFive(null);
      // setApiSix(null);
      // setApiTwoAndFour(null);
      // setApiTwoAndSix(null);
      // setApiTwoAndSeven(null);
      // setApiEpsDiluted(null);
      // setApiOneFourAndSix(null);
      // setApiTwoThreeAndSix(null);
    }
  }, [symbol]);

  useEffect(() => {
    // console.log("yes", activeYearsTab);
    // setApiOnePrice(null);
    // setApiOneMC(null);
    // setApiTwo(null);
    // setApiThree(null);
    // setApiFour(null);
    // setApiFive(null);
    // setApiTwoAndFour(null);
    // setApiTwoAndSeven(null);
    // setApiEpsDiluted(null);
    // setApiOneFourAndSix(null);
    // setApiTwoThreeAndSix(null);
    if (
      ApiOneStatus == "success" &&
      // ApiTwoStatus == "success" &&
      // ApiThreeStatus == "success" &&
      // ApiFourStatus == "success" &&
      // ApiFiveStatus == "success" &&
      // ApiSixStatus == "success" &&
      // ApiTwoAndFourStatus == "success" &&
      // ApiTwoAndSixStatus == "success" &&
      // ApiTwoAndSevenStatus == "success" &&
      // ApiEpsDilutedStatus == "success" &&
      // ApiOneFourAndSixStatus == "success" &&
      // ApiTwoThreeAndSixStatus == "success" &&
      ApiOneData.status == 200
      // ApiTwoData.status == 200 &&
      // ApiThreeData.status == 200 &&
      // ApiFourData.status == 200 &&
      // ApiFiveData.status == 200 &&
      // ApiSixData.status == 200 &&
      // ApiTwoAndFourData.status == 200 &&
      // ApiTwoAndSixData.status == 200 &&
      // ApiTwoAndSevenData.status == 200 &&
      // ApiEpsDilutedData.status == 200 &&
      // ApiOneFourAndSixData.status == 200 &&
      // ApiTwoThreeAndSixData.status == 200
    ) {
      setApiOnePrice(null);
      // setApiOneMC(null);
      // setApiTwo(null);
      // setApiThree(null);
      // setApiFour(null);
      // setApiFive(null);
      // setApiSix(null);
      // setApiTwoAndFour(null);
      // setApiTwoAndSix(null);
      // setApiTwoAndSeven(null);
      // setApiEpsDiluted(null);
      // setApiOneFourAndSix(null);
      // setApiTwoThreeAndSix(null);

      //filter Values for annual and quarter

      const apiTwo = ApiTwoData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearSmall;
      });
      // const apiThree = ApiThreeData.data.filter((item) => {
      //   const date = item.date.substr(0, 4);
      //   return date >= filterYearSmall;
      // });
      const apiFour = ApiFourData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearSmall;
      });
      const apiFive = ApiFiveData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearSmall;
      });
      const apiSix = ApiSixData.data.filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearSmall;
      });
      const apiTwoAndFour = ApiTwoAndFourData.data.filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearSmall;
      });
      const apiTwoAndSix = ApiTwoAndSixData.data.filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearSmall;
      });
      // const apiTwoAndSeven = ApiTwoAndSevenData.data.filter((item) => {
      //   const date = item.date.substr(0, 4);
      //   return date >= filterYearSmall;
      // });
      const apiEpsDiluted = ApiEpsDilutedData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearSmall;
      });
      const apiOneFourAndSix = ApiOneFourAndSixData.data.filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearSmall;
      });
      const apiTwoThreeAndSix = ApiTwoThreeAndSixData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearSmall;
      });

      //filter for modal

      const two = ApiTwoData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearModal;
      });
      // const three = ApiThreeData.data.filter((item) => {
      //   const date = item.date.substr(0, 4);
      //   return date >= filterYearModal;
      // });
      const four = ApiFourData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearModal;
      });
      const five = ApiFiveData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearModal;
      });
      const six = ApiSixData.data.filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearModal;
      });
      const twoAndFour = ApiTwoAndFourData.data.filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearModal;
      });
      const twoAndSix = ApiTwoAndSixData.data.filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearModal;
      });
      // const twoAndSeven = ApiTwoAndSevenData.data.filter((item) => {
      //   const date = item.date.substr(0, 4);
      //   return date >= filterYearModal;
      // });
      const epsDiluted = ApiEpsDilutedData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearModal;
      });
      const oneFourAndSix = ApiOneFourAndSixData.data.filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearModal;
      });
      const twoThreeAndSix = ApiTwoThreeAndSixData.data[0].filter((item) => {
        const date = item.date.substr(0, 4);
        return date >= filterYearModal;
      });

      setTimeout(() => {
        //set modal data values
        setPrice(ApiOneData.data[2]);
        setMarketCap(ApiOneData.data[3]);

        //set CAGR
        setCagrPrice(ApiOneData.data[4]);
        setCagrMC(ApiOneData.data[5]);
        setCagrRevenue(ApiTwoData.data[1]);
        setCagrFCF_SBC([ApiFourData.data[1], ApiFourData.data[2]]);
        setCagrEps([ApiEpsDilutedData.data[1], ApiEpsDilutedData.data[2]]);
        setCagrDividend(ApiFiveData.data[1]);
        setCagrNetIncome(ApiTwoData.data[2]);
        setCagrEbitda([
          ApiTwoThreeAndSixData.data[1],
          ApiTwoThreeAndSixData.data[2],
          ApiTwoThreeAndSixData.data[3],
        ]);
        setCagrWeightedAverage(ApiTwoData.data[3]);

        if (activeYearsTab === "max") {
          setApiOnePrice(ApiOneData.data[0]);
          setApiOneMC(ApiOneData.data[1]);
          setApiTwo(ApiTwoData.data[0]);
          // setApiThree(ApiThreeData.data);
          setApiFour(ApiFourData.data[0]);
          setApiFive(ApiFiveData.data[0]);
          setApiSix(ApiSixData.data);
          setApiTwoAndFour(ApiTwoAndFourData.data);
          setApiTwoAndSix(ApiTwoAndSixData.data);
          // setApiTwoAndSeven(ApiTwoAndSevenData.data);
          setApiEpsDiluted(ApiEpsDilutedData.data[0]);
          setApiOneFourAndSix(ApiOneFourAndSixData.data);
          setApiTwoThreeAndSix(ApiTwoThreeAndSixData.data[0]);
        } else {
          //old
          // setApiOnePrice(onePrice);
          // setApiOneMC(oneMC);

          //new
          setApiOnePrice(ApiOneData.data[0]);
          setApiOneMC(ApiOneData.data[1]);

          setApiTwo(two);
          // setApiThree(three);
          setApiFour(four);
          setApiFive(five);
          setApiSix(six);
          setApiTwoAndFour(twoAndFour);
          setApiTwoAndSix(twoAndSix);
          // setApiTwoAndSeven(twoAndSeven);
          setApiEpsDiluted(epsDiluted);
          setApiOneFourAndSix(oneFourAndSix);
          setApiTwoThreeAndSix(twoThreeAndSix);
        }

        // set values for small annual

        // setFilteredOnePrice(apiOnePrice);
        // setFilteredOneMC(apiOneMC);
        setFilteredTwo(apiTwo);
        // setFilteredThree(apiThree);
        setFilteredFour(apiFour);
        setFilteredFive(apiFive);
        setFilteredSix(apiSix);
        setFilteredTwoAndFour(apiTwoAndFour);
        setFilteredTwoAndSix(apiTwoAndSix);
        // setFilteredTwoAndSeven(apiTwoAndSeven);
        setFilteredEpsDiluted(apiEpsDiluted);
        setFilteredOneFourAndSix(apiOneFourAndSix);
        setFilteredTwoThreeAndSix(apiTwoThreeAndSix);

        // set values for small quarterly

        // setOnePrice(apiOnePrice);
        // setOneMC(apiOneMC);
        setTwo(apiTwo);
        // setThree(apiThree);
        setFour(apiFour);
        setFive(apiFive);
        setSix(apiSix);
        setTwoAndFour(apiTwoAndFour);
        setTwoAndSix(apiTwoAndSix);
        // setTwoAndSeven(apiTwoAndSeven);
        setEpsDiluted(apiEpsDiluted);
        setOneFourAndSix(apiOneFourAndSix);
        setTwoThreeAndSix(apiTwoThreeAndSix);

        setWarning(false);
      }, 1000);
    }
  }, [activeYearsTab]);

  // console.log(companyInfo.price, companyInfo.mktCap);

  // console.log("apiOnePrice", apiOnePrice);
  // console.log("apiOneMC", apiOneMC);
  // console.log("apiTwo", apiTwo);
  // console.log("apiThree", apiThree);
  // console.log("apiFour", apiFour);
  // console.log("apiFive", apiFive);
  // console.log("apiSix", apiSix);
  // console.log("apiTwoAndFour", apiTwoAndFour);
  // console.log("apiTwoAndSix", apiTwoAndSix);
  // console.log("apiTwoAndSeven", apiTwoAndSeven);
  // console.log("apiEpsDiluted", apiEpsDiluted);
  // console.log("apiOneFourAndSix", apiOneFourAndSix);
  // console.log("apiTwoThreeAndSix", apiTwoThreeAndSix);

  useEffect(() => {
    if (
      ApiOneStatus == "success" &&
      ApiTwoStatus == "success" &&
      // ApiThreeStatus == "success" &&
      ApiFourStatus == "success" &&
      ApiFiveStatus == "success" &&
      ApiSixStatus == "success" &&
      ApiTwoAndFourStatus == "success" &&
      ApiTwoAndSixStatus == "success" &&
      // ApiTwoAndSevenStatus == "success" &&
      ApiEpsDilutedStatus == "success" &&
      ApiOneFourAndSixStatus == "success" &&
      ApiTwoThreeAndSixStatus == "success"
    ) {
      // console.log(ApiOneData.data);
      // console.log(ApiOneData.data);
      // console.log(ApiTwoData.data); // 5
      // // console.log(ApiThreeData.data); // 5
      // console.log(ApiFourData.data);
      // console.log(ApiFiveData.data);
      // console.log(ApiSixData.data);
      // console.log(ApiTwoAndFourData.data);
      // console.log(ApiTwoAndSixData.data);
      // // console.log(ApiTwoAndSevenData.data);
      // console.log(ApiEpsDilutedData.data);
      // console.log(ApiOneFourAndSixData.data);
      // console.log(ApiTwoThreeAndSixData.data);
      if (
        ApiOneData.status == 200 &&
        ApiTwoData.status == 200 &&
        // ApiThreeData.status == 200 &&
        ApiFourData.status == 200 &&
        ApiFiveData.status == 200 &&
        ApiSixData.status == 200 &&
        ApiTwoAndFourData.status == 200 &&
        ApiTwoAndSixData.status == 200 &&
        // ApiTwoAndSevenData.status == 200 &&
        ApiEpsDilutedData.status == 200 &&
        ApiOneFourAndSixData.status == 200 &&
        ApiTwoThreeAndSixData.status == 200
      ) {
        // console.log(ApiOneData.data[1]);
        // console.log(ApiTwoData.data[0]); // 5
        // console.log(ApiThreeData.data); // 5
        // console.log(ApiFourData.data);
        // console.log(ApiFiveData.data);
        // console.log(ApiSixData.data);
        // console.log(ApiTwoAndFourData.data);
        // console.log(ApiTwoAndSixData.data);
        // console.log(ApiTwoAndSevenData.data);
        // console.log(ApiEpsDilutedData.data);
        // console.log(ApiOneFourAndSixData.data);
        // console.log(ApiTwoThreeAndSixData.data);

        //price and market cap
        setPrice(ApiOneData.data[2]);
        setMarketCap(ApiOneData.data[3]);

        //set CAGR
        setCagrPrice(ApiOneData.data[4]);
        setCagrMC(ApiOneData.data[5]);
        setCagrRevenue(ApiTwoData.data[1]);
        setCagrFCF_SBC([ApiFourData.data[1], ApiFourData.data[2]]);
        setCagrEps([ApiEpsDilutedData.data[1], ApiEpsDilutedData.data[2]]);
        setCagrDividend(ApiFiveData.data[1]);
        setCagrNetIncome(ApiTwoData.data[2]);
        setCagrEbitda([
          ApiTwoThreeAndSixData.data[1],
          ApiTwoThreeAndSixData.data[2],
          ApiTwoThreeAndSixData.data[3],
        ]);
        setCagrWeightedAverage(ApiTwoData.data[3]);

        //filter for modal
        if (activeYearsTab == "max") {
          setApiOnePrice(ApiOneData.data[0]);
          setApiOneMC(ApiOneData.data[1]);
          setApiTwo(ApiTwoData.data[0]);
          // setApiThree(ApiThreeData.data);
          setApiFour(ApiFourData.data[0]);
          setApiFive(ApiFiveData.data[0]);
          setApiSix(ApiSixData.data);
          setApiTwoAndFour(ApiTwoAndFourData.data);
          setApiTwoAndSix(ApiTwoAndSixData.data);
          // setApiTwoAndSeven(ApiTwoAndSevenData.data);
          setApiEpsDiluted(ApiEpsDilutedData.data[0]);
          setApiOneFourAndSix(ApiOneFourAndSixData.data);
          setApiTwoThreeAndSix(ApiTwoThreeAndSixData.data[0]);
        } else {
          const apiTwo = ApiTwoData.data[0].filter((item) => {
            const date = item.date.substr(0, 4);
            return date >= filterYearModal;
          });
          // const apiThree = ApiThreeData.data.filter((item) => {
          //   const date = item.date.substr(0, 4);
          //   return date >= filterYearModal;
          // });
          const apiFour = ApiFourData.data[0].filter((item) => {
            const date = item.date.substr(0, 4);
            return date >= filterYearModal;
          });
          const apiFive = ApiFiveData.data[0].filter((item) => {
            const date = item.date.substr(0, 4);
            return date >= filterYearModal;
          });
          const apiSix = ApiSixData.data.filter((item) => {
            const date = item.date.substr(0, 4);
            return date >= filterYearModal;
          });
          const apiTwoAndFour = ApiTwoAndFourData.data.filter((item) => {
            const date = item.date.substr(0, 4);
            return date >= filterYearModal;
          });
          const apiTwoAndSix = ApiTwoAndSixData.data.filter((item) => {
            const date = item.date.substr(0, 4);
            return date >= filterYearModal;
          });
          // const apiTwoAndSeven = ApiTwoAndSevenData.data.filter((item) => {
          //   const date = item.date.substr(0, 4);
          //   return date >= filterYearModal;
          // });
          const apiEpsDiluted = ApiEpsDilutedData.data[0].filter((item) => {
            const date = item.date.substr(0, 4);
            return date >= filterYearModal;
          });
          const apiOneFourAndSix = ApiOneFourAndSixData.data.filter((item) => {
            const date = item.date.substr(0, 4);
            return date >= filterYearModal;
          });
          const apiTwoThreeAndSix = ApiTwoThreeAndSixData.data[0].filter(
            (item) => {
              const date = item.date.substr(0, 4);
              return date >= filterYearModal;
            }
          );

          setApiOnePrice(ApiOneData.data[0]);
          setApiOneMC(ApiOneData.data[1]);

          setApiTwo(apiTwo);
          // setApiThree(apiThree);
          setApiFour(apiFour);
          setApiFive(apiFive);
          setApiSix(apiSix);
          setApiTwoAndFour(apiTwoAndFour);
          setApiTwoAndSix(apiTwoAndSix);
          // setApiTwoAndSeven(apiTwoAndSeven);
          setApiEpsDiluted(apiEpsDiluted);
          setApiOneFourAndSix(apiOneFourAndSix);
          setApiTwoThreeAndSix(apiTwoThreeAndSix);
        }

        //filter Values for small (annual and quarterly)

        const apiTwo = ApiTwoData.data[0].filter((item) => {
          const date = item.date.substr(0, 4);
          return date >= filterYearSmall;
        });
        // const apiThree = ApiThreeData.data.filter((item) => {
        //   const date = item.date.substr(0, 4);
        //   return date >= filterYearSmall;
        // });
        const apiFour = ApiFourData.data[0].filter((item) => {
          const date = item.date.substr(0, 4);
          return date >= filterYearSmall;
        });
        const apiFive = ApiFiveData.data[0].filter((item) => {
          const date = item.date.substr(0, 4);
          return date >= filterYearSmall;
        });
        const apiSix = ApiSixData.data.filter((item) => {
          const date = item.date.substr(0, 4);
          return date >= filterYearSmall;
        });
        const apiTwoAndFour = ApiTwoAndFourData.data.filter((item) => {
          const date = item.date.substr(0, 4);
          return date >= filterYearSmall;
        });
        const apiTwoAndSix = ApiTwoAndSixData.data.filter((item) => {
          const date = item.date.substr(0, 4);
          return date >= filterYearSmall;
        });
        // const apiTwoAndSeven = ApiTwoAndSevenData.data.filter((item) => {
        //   const date = item.date.substr(0, 4);
        //   return date >= filterYearSmall;
        // });
        const apiEpsDiluted = ApiEpsDilutedData.data[0].filter((item) => {
          const date = item.date.substr(0, 4);
          return date >= filterYearSmall;
        });
        const apiOneFourAndSix = ApiOneFourAndSixData.data.filter((item) => {
          const date = item.date.substr(0, 4);
          return date >= filterYearSmall;
        });
        const apiTwoThreeAndSix = ApiTwoThreeAndSixData.data[0].filter(
          (item) => {
            const date = item.date.substr(0, 4);
            return date >= filterYearSmall;
          }
        );

        // set values for annual
        setFilteredTwo(apiTwo);
        // setFilteredThree(apiThree);
        setFilteredFour(apiFour);
        setFilteredFive(apiFive);
        setFilteredSix(apiSix);
        setFilteredTwoAndFour(apiTwoAndFour);
        setFilteredTwoAndSix(apiTwoAndSix);
        // setFilteredTwoAndSeven(apiTwoAndSeven);
        setFilteredEpsDiluted(apiEpsDiluted);
        setFilteredOneFourAndSix(apiOneFourAndSix);
        setFilteredTwoThreeAndSix(apiTwoThreeAndSix);

        // set values for quarterly
        setTwo(apiTwo);
        // setThree(apiThree);
        setFour(apiFour);
        setFive(apiFive);
        setSix(apiSix);
        setTwoAndFour(apiTwoAndFour);
        setTwoAndSix(apiTwoAndSix);
        // setTwoAndSeven(apiTwoAndSeven);
        setEpsDiluted(apiEpsDiluted);
        setOneFourAndSix(apiOneFourAndSix);
        setTwoThreeAndSix(apiTwoThreeAndSix);
        setWarning(false);
      } else if (
        ApiOneData.status == 403 ||
        ApiTwoData.status == 403 ||
        // ApiThreeData.status == 403 ||
        ApiFourData.status == 403 ||
        ApiFiveData.status == 403 ||
        ApiSixData.status == 403 ||
        ApiTwoAndFourData.status == 403 ||
        ApiTwoAndSixData.status == 403 ||
        // ApiTwoAndSevenData.status == 403 ||
        ApiEpsDilutedData.status == 403 ||
        ApiOneFourAndSixData.status == 403 ||
        ApiTwoThreeAndSixData.status == 403 ||
        ApiOneData.status == 404 ||
        ApiTwoData.status == 404 ||
        // ApiThreeData.status == 404 ||
        ApiFourData.status == 404 ||
        ApiFiveData.status == 404 ||
        ApiSixData.status == 404 ||
        ApiTwoAndFourData.status == 404 ||
        ApiTwoAndSixData.status == 404 ||
        // ApiTwoAndSevenData.status == 404 ||
        ApiEpsDilutedData.status == 404 ||
        ApiOneFourAndSixData.status == 404 ||
        ApiTwoThreeAndSixData.status == 404
      ) {
        setApiOnePrice([]);
        setApiOneMC([]);
        setApiTwo([]);
        // setApiThree([]);
        setApiFour([]);
        setApiFive([]);
        setApiSix([]);
        setApiTwoAndFour([]);
        setApiTwoAndSix([]);
        // setApiTwoAndSeven([]);
        setApiEpsDiluted([]);
        setApiOneFourAndSix([]);
        setApiTwoThreeAndSix([]);
        setWarning(true);
      }
    }
  }, [
    ApiOneData,
    ApiOneStatus,
    ApiTwoData,
    ApiTwoStatus,
    // ApiThreeData,
    // ApiThreeStatus,
    ApiFourData,
    ApiFourStatus,
    ApiFiveData,
    ApiFiveStatus,
    ApiSixData,
    ApiSixStatus,
    ApiTwoAndFourData,
    ApiTwoAndFourStatus,
    ApiTwoAndSixData,
    ApiTwoAndSixStatus,
    // ApiTwoAndSevenData,
    // ApiTwoAndSevenStatus,
    ApiEpsDilutedData,
    ApiEpsDilutedStatus,
    ApiOneFourAndSixData,
    ApiOneFourAndSixStatus,
    ApiTwoThreeAndSixData,
    ApiTwoThreeAndSixStatus,
  ]);

  // console.log("1 P", apiOnePrice);
  // console.log("1 MC", apiOnePrice);
  // console.log("2", apiTwo);
  // console.log("3", apiThree);
  // console.log("4", apiFour);
  // console.log("5", apiFive);
  // console.log("6", apiSix);
  // console.log("2+4", apiTwoAndFour);
  // console.log("2+6", apiTwoAndSix);
  // console.log("2+7", apiTwoAndSeven);
  // console.log("eps", apiEpsDiluted);
  // console.log("1+4+6", apiOneFourAndSix);
  // console.log("2+3+6", apiTwoThreeAndSix);

  const periodTabOnclick = (tab) => {
    setActivePeriodTab(tab);

    //remove

    removeOne();
    removetwo();
    // removeThree();
    removeFour();
    removeFive();
    removeSix();
    removeTwoFour();
    removeTwoSix();
    // removeTwoSeven();
    removeEpsDiluted();
    removeTwoFourSix();
    removeTwoThreeSix();

    //null

    setApiOnePrice(null);
    setApiOneMC(null);
    setApiTwo(null);
    // setApiThree(null);
    setApiFour(null);
    setApiFive(null);
    setApiSix(null);
    setApiTwoAndFour(null);
    setApiTwoAndSix(null);
    // setApiTwoAndSeven(null);
    setApiEpsDiluted(null);
    setApiOneFourAndSix(null);
    setApiTwoThreeAndSix(null);

    if (tab == "annual") {
      yearsTabOnclick("5", tab);
    } else {
      yearsTabOnclick("3", tab);
    }
    setWarning(false);
  };

  const yearsTabOnclick = (year, period) => {
    setActiveYearsTab(year);
    if (year == "3") {
      setFilterYearSmall("2021");
      setFilterYearModal("2021");
    } else if (year == "5") {
      if (period == "annual") {
        setFilterYearSmall("2018");
        setFilterYearModal("2018");
      } else {
        setFilterYearSmall("2021");
        setFilterYearModal("2018");
      }
    } else if (year == "10") {
      if (period == "annual") {
        setFilterYearSmall("2013");
        setFilterYearModal("2013");
      } else {
        setFilterYearSmall("2021");
        setFilterYearModal("2013");
      }
    } else if (year == "max") {
      if (period == "annual") {
        setFilterYearSmall("2009");
        setFilterYearModal("");
      } else {
        setFilterYearSmall("2021");
        setFilterYearModal("");
      }
    }
  };

  const selectOnClick = (value) => {
    setSymbol(value);
    setFlag(true);
  };

  const dailyRequest = (val) => {
    // console.log("clicked", val);
    setDailyData(val);
    removeOne();
  };
  // console.log(price, marketCap);

  return (
    <>
      <div className="flex flex-col border-gray-300 border-t-2 border-b-2 h-14 md:flex-row flex-wrap sm:space-x-2 md:space-x-3 lg:space-x-2  pt-2 ">
        <div className="flex w-[18%] lg:w-[15%] xl:w-[12%]">
          <SearchBar
            selectOnClick={selectOnClick}
            setCompanyInfo={setCompanyInfo}
          />
        </div>
        <div className="flex flex-row w-[35%] lg:w-[28%] xl:w-[24%] space-x-3 ">
          <Tabs
            text={"3 Y"}
            tabOnclick={yearsTabOnclick}
            myactiveTab={"3"}
            activeTab={activeYearsTab}
            period={activePeriodTab}
          />
          <Tabs
            text={"5 Y"}
            tabOnclick={yearsTabOnclick}
            myactiveTab={"5"}
            activeTab={activeYearsTab}
            period={activePeriodTab}
          />
          <Tabs
            text={"10 Y"}
            tabOnclick={yearsTabOnclick}
            myactiveTab={"10"}
            activeTab={activeYearsTab}
            period={activePeriodTab}
          />
          <Tabs
            text={"Max"}
            tabOnclick={yearsTabOnclick}
            myactiveTab={"max"}
            activeTab={activeYearsTab}
            period={activePeriodTab}
          />
        </div>

        <div className="flex flex-row w-[25%] lg:w-[37%] xl:w-[42%] justify-start space-x-3">
          <Tabs
            text={"Yearly"}
            tabOnclick={periodTabOnclick}
            myactiveTab={"annual"}
            activeTab={activePeriodTab}
          />
          <Tabs
            text={"Quarterly"}
            tabOnclick={periodTabOnclick}
            myactiveTab={"quarter"}
            activeTab={activePeriodTab}
          />
        </div>

        {/* <div className="flex flex-col justify-center items-center">
          <p className="text-xl font-bold">Unit</p>
           <div className="flex flex-row space-x-3">
            <Tabs
              text={"Thousand"}
              tabOnclick={UnitTabOnclick}
              myactiveTab={" th"}
              activeTab={activeUnitTab}
            />
            <Tabs
              text={"Million"}
              tabOnclick={UnitTabOnclick}
              myactiveTab={" m"}
              activeTab={activeUnitTab}
            />
            <Tabs
              text={"Billion"}
              tabOnclick={UnitTabOnclick}
              myactiveTab={" B"}
              activeTab={activeUnitTab}
            />
          </div> 
        </div> */}
        <div className="flex flex-row w-[14%] lg:w-[16%] xl:w-[16%] justify-around">
          <DarkModeToggleComponent />
          <ProfileView/>
        </div>
      </div>
      <div className=" flex-row pl-4 pt-2">
        {companyInfo && (
          <div className="flex flex-row">
            <img
              src={companyInfo.image}
              alt="companyIcon"
              className="h-14 w-14 text-white ml-1 bg-gray-950 rounded-full mb-3"
            />
            <div className="pl-5 pt-3">
              <h1 className="text-[2rem] md:text-2xl font-normal font-roboto">
                {companyInfo.companyName}{" "}
                {companyInfo?.exchangeShortName?.split(" ")[0]}
              </h1>
            </div>
          </div>
        )}
      </div>
      {apiOnePrice &&
      apiOneMC &&
      apiTwo &&
      // apiThree &&
      apiFour &&
      apiFive &&
      apiSix &&
      apiTwoAndFour &&
      apiTwoAndSix &&
      // apiTwoAndSeven &&
      apiEpsDiluted &&
      apiOneFourAndSix &&
      apiTwoThreeAndSix ? (
        warning !== true ? (
          <div className="flex flex-col">
            {/* Graph View */}

            <div className="flex flex-col md:flex-row justify-evenly items-center flex-wrap md:space-x-2 lg:space-x-4 py-10 px-3">
              <div className=" h-64 w-120 flex justify-center items-center mb-4 ">
                <AreaChart
                  allDataP={apiOnePrice}
                  allDataMC={apiOneMC}
                  period={activePeriodTab}
                  optionsArray={["date", "close"]}
                  years={activeYearsTab}
                  dailyRequest={dailyRequest}
                  p={price}
                  mc={marketCap}
                  currencyText={
                    companyInfo?.currency ? companyInfo.currency : "USD"
                  }
                  cagrPrice={cagrPrice}
                  cagrMC={cagrMC}
                  // unit={activeUnitTab}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <BasicColumnGraph
                  graphData={apiTwo}
                  optionsArray={["date", "revenue"]}
                  period={activePeriodTab}
                  chartNum={"2"}
                  fd={filteredTwo}
                  qd={two}
                  years={activeYearsTab}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  cagr={cagrRevenue}
                  // unit={activeUnitTab}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <Graph3
                  graphData={apiTwoAndSix}
                  optionsArray={[
                    "date",
                    "grossProfitRatio",
                    "operatingIncomeRatio",
                    "roic",
                  ]}
                  period={activePeriodTab}
                  fd={filteredTwoAndSix}
                  qd={twoAndSix}
                  years={activeYearsTab}
                  // currencyText={filteredTwo[0]?.reportedCurrency ? filteredTwo[0].reportedCurrency : "USD"}
                  // unit={activeUnitTab}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <Graph4
                  graphData={apiFour}
                  optionsArray={[
                    "date",
                    "freeCashFlow",
                    "stockBasedCompensation",
                  ]}
                  period={activePeriodTab}
                  fd={filteredFour}
                  qd={four}
                  years={activeYearsTab}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  cagr={cagrFCF_SBC}
                  // unit={activeUnitTab}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <Graph5
                  graphData={apiEpsDiluted}
                  optionsArray={["date", "epsdiluted", "fcfPerShare"]}
                  period={activePeriodTab}
                  // unit={activeUnitTab}
                  fd={filteredEpsDiluted}
                  qd={epsDiluted}
                  years={activeYearsTab}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  cagr={cagrEps}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <BasicColumnGraph
                  graphData={apiFive}
                  optionsArray={["date", "adjDividend"]}
                  period={activePeriodTab}
                  // unit={activeUnitTab}
                  chartNum={"6"}
                  years={activeYearsTab}
                  fd={filteredFive}
                  qd={five}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  cagr={cagrDividend}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <BasicColumnGraph
                  graphData={apiTwo}
                  optionsArray={["date", "netIncome"]}
                  period={activePeriodTab}
                  chartNum={"7"}
                  fd={filteredTwo}
                  qd={two}
                  years={activeYearsTab}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  // unit={activeUnitTab}
                  cagr={cagrNetIncome}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <StackedGraph
                  graphData={apiTwoAndFour}
                  optionsArray={[
                    "date",
                    "sellingGeneralAndAdministrativeExpenses",
                    "researchAndDevelopmentExpenses",
                    "sellingAndMarketingExpenses",
                    "capitalExpenditure",
                  ]}
                  period={activePeriodTab}
                  fd={filteredTwoAndFour}
                  qd={twoAndFour}
                  years={activeYearsTab}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  // unit={activeUnitTab}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <Graph9
                  graphData={apiTwoThreeAndSix}
                  optionsArray={[
                    "date",
                    "ebitda",
                    "netDebt",
                    "netDebtToEBITDA",
                  ]}
                  period={activePeriodTab}
                  // unit={activeUnitTab}
                  chartNum={"9"}
                  fd={filteredTwoThreeAndSix}
                  qd={twoThreeAndSix}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  years={activeYearsTab}
                  cagr={cagrEbitda}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <BasicColumnGraph
                  graphData={apiSix}
                  optionsArray={["date", "interestCoverage"]}
                  period={activePeriodTab}
                  chartNum={"10"}
                  fd={filteredSix}
                  qd={six}
                  years={activeYearsTab}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  // unit={activeUnitTab}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <Graph11
                  graphData={apiOneFourAndSix}
                  optionsArray={[
                    "date",
                    "peRatio",
                    "pbRatio",
                    "roe",
                    "returnOnTangibleAssets",
                    "fcfYield",
                  ]}
                  period={activePeriodTab}
                  fd={filteredOneFourAndSix}
                  qd={oneFourAndSix}
                  years={activeYearsTab}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  // unit={activeUnitTab}
                />
              </div>

              <div className=" h-64 w-120 flex justify-center items-center mb-4">
                <BasicColumnGraph
                  graphData={apiTwo}
                  optionsArray={["date", "weightedAverageShsOutDil"]}
                  period={activePeriodTab}
                  chartNum={"12"}
                  fd={filteredTwo}
                  qd={two}
                  years={activeYearsTab}
                  currencyText={
                    filteredTwo[0]?.reportedCurrency
                      ? filteredTwo[0].reportedCurrency
                      : "USD"
                  }
                  cagr={cagrWeightedAverage}
                  // unit={activeUnitTab}
                />
              </div>
            </div>
          </div>
        ) : (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "13rem",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            Select a Company
          </p>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "13rem",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default GraphsComponent;
