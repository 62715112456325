import React, { useState, useContext } from "react";
import { ThemeContext } from "../../../Context/ThemeContext";

function PeriodTabs({ text, tabOnclick, myactiveTab, activeTab, period }) {
  const { darkMode } = useContext(ThemeContext);

  return (
    <div>
      <p
        className={
          activeTab === myactiveTab
            ? darkMode
              ? " font-roboto cursor-pointer pb-2 bg-white rounded-full text-black p-1 px-5 font-bold"
              : " font-roboto cursor-pointer pb-2 bg-gray-950 rounded-full text-white p-1 px-5 font-bold"
            : darkMode
            ? " font-roboto  hover:bg-gray-400 cursor-pointer bg-gray-500 rounded-full p-1 px-5"
            : " font-roboto  hover:bg-gray-300 cursor-pointer bg-gray-300 rounded-full p-1 px-5"
        }
        onClick={() => {
          tabOnclick(myactiveTab, period);
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default PeriodTabs;
