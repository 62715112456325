import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import "../style/style.css";
import { ExpandAltOutlined } from "@ant-design/icons";
import "../style/style.css";
import CustomModal from "../components/CustomModal";
import getSymbolFromCurrency from "currency-symbol-map";
import { ThemeContext } from "../../../Context/ThemeContext";
import Cagr from "../components/Cagr";

function Graph5({
  graphData,
  fd,
  qd,
  period,
  // unit,
  years,
  optionsArray,
  currencyText,
  cagr,
}) {
  const { darkMode } = useContext(ThemeContext);
  const [smallChartData, setSmallChartData] = useState(
    period == "annual" ? fd : qd
  );

  const [allData, setAllData] = useState(graphData);
  const [currency, setCurrency] = useState(getSymbolFromCurrency(currencyText));

  const [visible, setVisible] = useState(null);
  const [options, setOptions] = useState({});
  const [modalOptions, setModalOptions] = useState({});

  const [smallChartSeries, setSmallChartSeries] = useState([]);
  const [modalSeries, setModalSeries] = useState([]);

  const [activePeriodTab, setActivePeriodTab] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dispaly, setDisplay] = useState(false);
  const [smallDisplay, setSmallDisplay] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDisplay(false);
  };

  const color = ["#f1ce63", "#2E8BC0"];
  const markerColor = ["#f1ce63", "#2E8BC0"];

  useEffect(() => {
    setSmallDisplay(false);
    // console.log(annualData);
    smallChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
    allData.sort((a, b) => new Date(a.date) - new Date(b.date));

    // let denominator;
    // if (unit == " th") {
    //   denominator = 1000;
    // } else if (unit == " m") {
    //   denominator = 1000000;
    // } else if (unit == " B") {
    //   denominator = 1000000000;
    // }

    setTimeout(() => setSmallDisplay(true), 10);

    const newOptionsObject = {
      epsdiluted: "EPS",
      fcfPerShare: "FCF per share",
    };

    const newOptionsEmptyObject = {
      epsdiluted: "E̶P̶S̶",
      fcfPerShare: "F̶C̶F̶ p̶e̶r̶ s̶h̶a̶r̶e̶",
    };

    //calculations

    // const calculatedSmallObject = smallChartData.map((item) => {
    //   const freeCashFlow = item["freeCashFlow"] ? item["freeCashFlow"] : 0;
    //   const weightedAverageShsOutDil = item["weightedAverageShsOutDil"]
    //     ? item["weightedAverageShsOutDil"]
    //     : 1;

    //   const fcfPerShare = freeCashFlow / weightedAverageShsOutDil;

    //   return {
    //     date: item.date,
    //     epsdiluted: item.epsdiluted,
    //     epsEstimated: item.epsEstimated,
    //     fcfPerShare: fcfPerShare,
    //   };
    // });

    // const calculatedModalObject = allData.map((item) => {
    //   const freeCashFlow = item["freeCashFlow"] ? item["freeCashFlow"] : 0;
    //   const weightedAverageShsOutDil = item["weightedAverageShsOutDil"]
    //     ? item["weightedAverageShsOutDil"]
    //     : 0;

    //   const fcfPerShare = freeCashFlow / weightedAverageShsOutDil;

    //   return {
    //     date: item.date,
    //     epsdiluted: item.epsdiluted,
    //     epsEstimated: item.epsEstimated,
    //     fcfPerShare: fcfPerShare,
    //   };
    // });

    //for series

    let smallChartSeriesObjects = [];

    smallChartSeriesObjects = optionsArray.map((option) => {
      if (option !== "date") {
        // const a = smallChartData.map((item) => {
        //   // return item[option] ? item[option] : 0;
        //   return {
        //     x: item.date.toString().substr(0, 4),
        //     y: item[option] && item[option] !== 0 ? item[option] : undefined,
        //   };
        // });
        // return {
        //   name: newOptionsObject[option],
        //   data: a,
        // };
        let a;
        if (option == "epsdiluted") {
          a = smallChartData.map((item) => {
            return {
              x: item.date,
              y: item[option] && item[option] !== 0 ? item[option] : null,
              goals: [
                {
                  name: "Estimated",
                  value: item["epsEstimated"] ? item["epsEstimated"] : 0,
                  strokeHeight: 2,
                  strokeColor: "#ff6962",
                },
              ],
            };
          });
        } else {
          a = smallChartData.map((item) => {
            return {
              x: item.date,
              y: item[option] && item[option] !== 0 ? item[option] : null,
            };
          });
        }

        const filtered = a.filter((item) => {
          return item.y !== null;
        });

        if (filtered.length > 0) {
          return {
            name: newOptionsObject[option],
            data: a,
          };
        } else {
          return {
            name: newOptionsEmptyObject[option],
            data: [],
          };
        }
      }
    });

    const smallChartSeriesObjectArray = smallChartSeriesObjects.filter(
      (item) => {
        return item !== undefined;
      }
    );

    // console.log("smallChartSeriesObjectArray", smallChartSeriesObjectArray);

    //for modal series

    let modalSeriesObject = [];

    modalSeriesObject = optionsArray.map((option) => {
      if (option !== "date") {
        //   const a = calculatedModalObject.map((item) => {
        //     // return item[option] ? item[option] : 0;
        //     return {
        //       x: item.date.toString().substr(0, 4),
        //       y: item[option] && item[option] !== 0 ? item[option] : undefined,
        //     };
        //   });
        //   return {
        //     name: newOptionsObject[option],
        //     data: a,
        //   };
        let a;
        if (option == "epsdiluted") {
          a = allData.map((item) => {
            // return item[option] && item[option] !== 0 ? item[option] : null;
            return {
              x: item.date,
              y: item[option] && item[option] !== 0 ? item[option] : null,
              goals: [
                {
                  name: "Estimated",
                  value: item["epsEstimated"] ? item["epsEstimated"] : 0,
                  strokeHeight: 2,
                  strokeColor: "#ff6962",
                },
              ],
            };
          });
        } else {
          a = allData.map((item) => {
            return {
              x: item.date,
              y: item[option] && item[option] !== 0 ? item[option] : null,
            };
          });
        }
        const filtered = a.filter((item) => {
          return item.y !== null;
        });
        if (filtered.length > 0) {
          return {
            name: newOptionsObject[option],
            data: a,
          };
        } else {
          return {
            name: newOptionsEmptyObject[option],
            data: [],
          };
        }
      }
    });

    const modalSeriesObjectArray = modalSeriesObject.filter((item) => {
      return item !== undefined;
    });

    // console.log("ModalSeriesObjectArray", modalSeriesObjectArray);

    //for x-axis or dates

    const smallChartDates = smallChartData.map((item) => {
      return `${item.date.substr(0, 4)}`;
    });

    const allDates = allData.map((item) => {
      return `${item.date.substr(0, 4)}/${item.date.substr(
        5,
        2
      )}/${item.date.substr(8, 2)}`;
      // return `${item.date.substr(0, 4)}`;
    });

    setOptions({
      tooltip: {
        marker: {
          // show: true,
          show: false,
          fillColors: markerColor,
        },
        theme: darkMode ? "dark" : "light",
        y: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return currency + " " + v.toFixed(2) + " " + unit;
          },
        },
      },
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          hideZeroBarsWhenGrouped: true,
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "70%"
                : "70%"
              : years == "10"
              ? period == "annual"
                ? "70%"
                : "70%"
              : period == "annual"
              ? "70%"
              : "70%",
          barHeight: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // width:
        //   years == "5"
        //     ? period == "annual"
        //       ? 4
        //       : 4
        //     : years == "10"
        //     ? period == "annual"
        //       ? 4
        //       : 4
        //     : period == "annual"
        //     ? 4
        //     : 4,
        colors: color,
      },

      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      xaxis: {
        forceNiceScale: true,
        type: "category",
        labels: {
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          rotate: -45,
          rotateAlways: true,
          style: {
            fontSize: "12px",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        categories: smallChartDates,
        tickPlacement: "on",
        tickAmount: 24,
      },
      yaxis: {
        forceNiceScale: true,
        // max:
        labels: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }

            return currency + v.toString().substring(0, 4);
          },
          style: {
            fontSize: "14px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        tickAmount: 7,
      },
      legend: {
        position: "top",
        labels: {
          colors: darkMode ? "#fff" : "#000",
        },
        showForSingleSeries: true,
        markers: {
          fillColors: markerColor,
          width: 30,
          height: 12,
        },
      },

      fill: {
        opacity: 1,
        colors: color,
      },
    });

    setModalOptions({
      tooltip: {
        marker: {
          // show: true,
          show: false,
          fillColors: markerColor,
        },
        theme: darkMode ? "dark" : "light",
        y: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";
            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return currency + " " + v.toFixed(2) + " " + unit;
          },
        },
      },
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          hideZeroBarsWhenGrouped: true,
          horizontal: false,
          columnWidth:
            years == "5"
              ? period == "annual"
                ? "60%"
                : "60%"
              : years == "10"
              ? period == "annual"
                ? "40%"
                : "70%"
              : period == "annual"
              ? "70%"
              : "60%",
          endingShape: "rounded",
          barHeight: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        // width:
        //   years == "5"
        //     ? period == "annual"
        //       ? 4
        //       : 6
        //     : years == "10"
        //     ? period == "annual"
        //       ? 4
        //       : 5
        //     : period == "annual"
        //     ? 3
        //     : 3,
        colors: color,
      },
      grid: {
        borderColor: darkMode ? "#3d3d3d" : "#ccc",
        row: {
          colors: darkMode ? undefined : ["#fff"],
        },
      },
      xaxis: {
        forceNiceScale: true,
        type: "category",
        labels: {
          // hideOverlappingLabels: true,
          formatter: function (val) {
            return period == "annual"
              ? dayjs(val).format("YYYY")
              : "Q" + dayjs(val).quarter() + " '" + dayjs(val).format("YY");
          },
          rotate: -45,
          rotateAlways: true,
          style: {
            fontSize: "14px",
            // fontWeight: "bold",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        categories: allDates,
        tickPlacement: "on",
        tickAmount: period == "annual" ? 45 : 60,
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            let v = parseFloat(value);
            let unit = "";

            if (v >= 1000000000) {
              v = v / 1000000000;
              unit = "B";
            } else if (v >= 1000000) {
              v = v / 1000000;
              unit = "M";
            } else if (v >= 1000) {
              v = v / 1000;
              unit = "K";
            } else if (v < 0) {
              if (v <= -1000000000) {
                v = v / 1000000000;
                unit = "B";
              } else if (v <= -1000000) {
                v = v / 1000000;
                unit = "M";
              } else if (v <= -1000) {
                v = v / 1000;
                unit = "K";
              }
            }
            return currency + v.toString().substring(0, 4);
          },
          style: {
            fontSize: "14px",
            colors: darkMode ? "#fff" : "#000",
          },
        },
        tickAmount: 8,
      },
      legend: {
        position: "top",
        labels: {
          colors: darkMode ? "#fff" : "#000",
        },
        showForSingleSeries: true,
        markers: {
          fillColors: markerColor,
          width: 35,
          height: 12,
        },
      },
      fill: {
        opacity: 1,
        colors: color,
      },
    });

    setSmallChartSeries(smallChartSeriesObjectArray);

    setModalSeries(modalSeriesObjectArray);
    setVisible(true);
  }, [allData, darkMode]);

  return (
    <>
      {visible ? (
        <div>
          {smallDisplay ? (
            <div>
              <div
                className="flex justify-end cursor-pointer"
                onClick={showModal}
              >
                <ExpandAltOutlined
                  style={{
                    fontSize: "18px",
                    color: darkMode ? "#6e8192" : "#3B3B3B",
                  }}
                />
              </div>

              <div className="chart">
                <ReactApexChart
                  options={options}
                  series={smallChartSeries}
                  type="bar"
                  height={250}
                  width={isMobile ? 220 : 450}
                />
              </div>
            </div>
          ) : (
            <div className="flex w-[27rem] h-[12rem] justify-center items-center">
              Loading...
            </div>
          )}

          <CustomModal
            visible={isModalOpen}
            onCancel={handleCancel}
            display={dispaly}
            setDisplay={setDisplay}
            isModalOpen={isModalOpen}
          >
            <div className="chart flex flex-col">
              <ReactApexChart
                options={modalOptions}
                series={modalSeries}
                type="bar"
                height={530}
                width={isMobile ? 300 : 1250}
              />
              <div className="flex flex-col">
                <Cagr
                  cagr={cagr[0]}
                  bg="#F8FAD8"
                  text="#E89A06"
                  className="flex flex-row p-1 px-2 rounded-md"
                />{" "}
                <Cagr
                  cagr={cagr[1]}
                  bg="#9ED8F8"
                  text="#2E8BC0"
                  className="flex flex-row p-1 px-2 rounded-md"
                />
              </div>
            </div>
          </CustomModal>
        </div>
      ) : (
        <div className="flex w-[27rem] h-[12rem] justify-center items-center">
          Loading...
        </div>
      )}
    </>
  );
}

export default Graph5;
